import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from 'constants/routes';

const HereSubscriptionLink = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(ROUTES.subscriptionSelect);
  };

  return (
    <a className="text-primary-300 cursor-pointer hover:text-primary-200" onClick={handleClick}>
      {t('HereLower')}
    </a>
  );
};

export default HereSubscriptionLink;
