import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { ButtonVariants } from 'constants/common';
import { cn } from 'utils/global';

import Button from './button/Button';

export interface Props {
  closeButtonText?: string;
  saveButtonText?: string;
  onSubmit?: VoidFunction;
  onClose: VoidFunction;
  isError?: boolean;
  isShownSubmit?: boolean;
  className?: string;
  dataCy?: string;
  disabled?: boolean;
  isLoading?: boolean;
}

const ModalControlButtons: FC<Props> = ({
  closeButtonText = 'Cancel',
  saveButtonText = 'Save',
  onSubmit,
  onClose,
  isError,
  className,
  isShownSubmit,
  isLoading,
  disabled,
}) => {
  const { t } = useTranslation();
  return (
    <div
      data-cy="modal-control-button"
      className={cn(
        cn('flex flex-col-reverse sm:flex-row rounded mt-5 place-content-center mb-4', {
          'justify-end': !isError,
        }),
        className
      )}
    >
      <Button
        className={cn({ 'bg-blue4 border-0 hover:bg-linearGradient text-white': isError })}
        type="button"
        dataCy="close-button"
        onClick={onClose}
        variant={ButtonVariants.OUTLINE}
      >
        {isError ? t('Ok') : t(closeButtonText)}
      </Button>
      {(onSubmit || isShownSubmit) && (
        <Button
          type={onSubmit ? 'button' : 'submit'}
          isLoading={isLoading}
          onClick={onSubmit}
          dataCy="submit-button"
          disabled={disabled}
          className="sm:ml-3 mb-3 sm:mb-0"
        >
          {t(saveButtonText)}
        </Button>
      )}
    </div>
  );
};

export default ModalControlButtons;
