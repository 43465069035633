import TreeView, { flattenTree, ITreeViewState } from 'react-accessible-treeview';
import { useFormContext } from 'react-hook-form';

import { SUBSCRIPTION_FEATURES_ELEMENTS } from 'constants/subscriptions-admin';
import { SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES } from 'interfaces/subscriptions-admin.interface';

import { renderNode } from './NodeRenderer';

const SubscriptionFeaturesTab = () => {
  const { register, control, setValue, getValues } = useFormContext();

  const subscriptionFeatures = flattenTree({
    name: '',
    children: SUBSCRIPTION_FEATURES_ELEMENTS,
  });

  const selectedIds = SUBSCRIPTION_FEATURES_ELEMENTS.filter((element) => getValues(element.id)).map(
    (element) => element.id
  );

  const handleChangeSelectedIds = ({ treeState }: { treeState: ITreeViewState }) => {
    const managingUsersValues = Array.from(treeState.selectedIds).some(
      (id) => id === SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.MANAGING_USERS
    );
    setValue(SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.MANAGING_USERS, managingUsersValues);
  };

  return (
    <div>
      <TreeView
        data={subscriptionFeatures}
        aria-label="create-subscription-features"
        multiSelect
        defaultSelectedIds={selectedIds}
        togglableSelect
        onSelect={handleChangeSelectedIds}
        nodeRenderer={renderNode(register, control)}
      />
    </div>
  );
};

export default SubscriptionFeaturesTab;
