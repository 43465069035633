import { useState } from 'react';

import qs from 'query-string';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { useResetPasswordMutation } from 'apis/auth.api';
import { ButtonVariants } from 'constants/common';
import {
  RESET_PASSWORD_SUCCESS_MESSAGE,
  RESET_PASSWORD_DESCRIPTION_MESSAGE,
  RESET_PASSWORD_PAGE_BACK_BUTTON,
  RESET_PASSWORD_PAGE_SUBMIT_BUTTON,
} from 'constants/reset-password';
import { ROUTES } from 'constants/routes';
import { confirmPasswordValidation, PASSWORD_VALIDATION } from 'constants/sign-up';
import { useOpen } from 'hooks/useOpen';
import { RESET_PASSWORD_FROM_FIELDS, SetPasswordFormValues } from 'interfaces/reset-password.interface';
import { Button, Input, ErrorModal, SuccessModal } from 'shared-components';
import PasswordStrengthWidget from 'shared-components/PasswordStrengthWidget';
import { cn } from 'utils/global';

const ResetPasswordForm = () => {
  const { search } = useLocation();
  const navigate = useNavigate();

  const [isShownPasswordWidget, setIsShownPasswordWidget] = useState(false);

  const [resetPassword, { data, isLoading }] = useResetPasswordMutation();

  const [isOpenSuccessModal, handleOnOpenSuccessModal, handleCloseSuccessModal] = useOpen();
  const [isOpenErrorModal, handleOnOpenErrorModal, handleOnCloseErrorModal] = useOpen();

  const { t } = useTranslation();

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<SetPasswordFormValues>({ mode: 'onBlur' });

  const { c: searchCode } = qs.parse(search);
  const watchPassword = watch(RESET_PASSWORD_FROM_FIELDS.newPassword);

  const toggleIsShownPasswordWidget = () => setIsShownPasswordWidget((prev) => !prev);

  const closeSuccessModal = () => {
    handleCloseSuccessModal();
    navigate(ROUTES.signIn);
  };

  const onSubmit = async (data: SetPasswordFormValues) => {
    const resetPasswordPayload = {
      newPassword: data[RESET_PASSWORD_FROM_FIELDS.confirmPassword],
      c: searchCode as string,
    };

    try {
      const resetPasswordResponse = await resetPassword(resetPasswordPayload).unwrap();

      if (resetPasswordResponse.success) {
        handleOnOpenSuccessModal();
      }
    } catch (err) {
      handleOnOpenErrorModal();
      console.error(err);
    }
  };

  return (
    <>
      <SuccessModal
        successMessage={RESET_PASSWORD_SUCCESS_MESSAGE}
        description={RESET_PASSWORD_DESCRIPTION_MESSAGE}
        isOpen={isOpenSuccessModal}
        handleClose={closeSuccessModal}
      />
      <ErrorModal
        errorMessage={data?.error?.message}
        description={data?.error?.details}
        isOpen={isOpenErrorModal}
        handleClose={handleOnCloseErrorModal}
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          {...register(RESET_PASSWORD_FROM_FIELDS.newPassword, PASSWORD_VALIDATION)}
          error={errors[RESET_PASSWORD_FROM_FIELDS.newPassword]?.message}
          onFocus={toggleIsShownPasswordWidget}
          onBlur={toggleIsShownPasswordWidget}
          type="password"
          label={t('Password')}
          className="mb-4 mt-10"
          inputClassName="w-full"
          required
        />
        <PasswordStrengthWidget
          password={watchPassword}
          className={cn(
            'col-span-2 row-start-5 row-end-6',
            { block: isShownPasswordWidget },
            { hidden: !isShownPasswordWidget }
          )}
        />

        <Input
          {...register(RESET_PASSWORD_FROM_FIELDS.confirmPassword, confirmPasswordValidation(watchPassword))}
          error={errors[RESET_PASSWORD_FROM_FIELDS.confirmPassword]?.message}
          onFocus={toggleIsShownPasswordWidget}
          onBlur={toggleIsShownPasswordWidget}
          type="password"
          label={t('ConfirmNewPassword')}
          className="mb-4 mt-4"
          inputClassName="w-full"
          required
        />

        <div className="flex items-center justify-between">
          <Button type="button" variant={ButtonVariants.PLAIN} isLoading={isLoading}>
            <Link to={ROUTES.signIn} className="text-sm">
              {t(RESET_PASSWORD_PAGE_BACK_BUTTON)}
            </Link>
          </Button>
          <Button className="py-2 px-4" type="submit" disabled={isLoading}>
            {t(RESET_PASSWORD_PAGE_SUBMIT_BUTTON)}
          </Button>
        </div>
      </form>
    </>
  );
};

export default ResetPasswordForm;
