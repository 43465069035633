import { useTranslation } from 'react-i18next';

import { useUpdateUserProfilePictureMutation } from 'apis/user.api';
import DefaultUserAvatar from 'assets/png/user.png';
import { ButtonVariants } from 'constants/common';
import { useOpen } from 'hooks/useOpen';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { setProfilePictureId } from 'modules/user/action';
import { selectCurrentUserId } from 'modules/user/selector';
import { Button, ErrorModal, Skeleton } from 'shared-components';

import ProfileInformationAvatarModal from './ProfileInformationAvatartModal';

const IMAGE_DIMENSION = '128';

interface Props {
  userId?: number;
  userPicture?: string | null;
  isProfilePictureLoading: boolean;
  handleNestedModalIsOpenedChange: (isNested: boolean) => void;
}

const ProfileInformationAvatarSettings = ({
  userId,
  userPicture,
  isProfilePictureLoading,
  handleNestedModalIsOpenedChange,
}: Props) => {
  const dispatch = useAppDispatch();

  const currentUserId = useAppSelector(selectCurrentUserId);

  const [updateProfilePicture, { isLoading: isUpdateProfilePictureLoading, data }] =
    useUpdateUserProfilePictureMutation();

  const [isAvatarModalOpen, handleAvatarModalOpen, handleAvatarModalClose] = useOpen();
  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();

  const isLoading = isProfilePictureLoading || isUpdateProfilePictureLoading;

  const { t } = useTranslation();

  const onSubmit = async (base64Data: string, mimeType: string) => {
    if (!userId) {
      return;
    }

    try {
      const { result } = await updateProfilePicture({
        base64Content: base64Data,
        mimeType,
        userId,
        tenantId: null,
      }).unwrap();

      if (userId === currentUserId) {
        dispatch(setProfilePictureId(result));
      }

      handleAvatarModalClose();
    } catch (error) {
      handleOpenErrorModal();
    }
  };

  return (
    <>
      <div className="flex flex-col justify-center items-center shrink-0 grow-0 w-full xs:max-w-[25%] xs:basis-1/4">
        {isLoading ? (
          <Skeleton className="max-w-full h-auto p-1 border border-gray rounded bg-white w-32 h-32" />
        ) : (
          <img
            width={IMAGE_DIMENSION}
            height={IMAGE_DIMENSION}
            className="max-w-full h-auto p-1 border border-gray rounded bg-white"
            src={userPicture || DefaultUserAvatar}
            alt="user-avatar"
          />
        )}
        <Button className="h-fit" type="button" variant={ButtonVariants.OUTLINE} onClick={handleAvatarModalOpen}>
          {t('ChangeProfilePicture')}
        </Button>
      </div>
      <ProfileInformationAvatarModal
        isOpen={isAvatarModalOpen}
        onClose={handleAvatarModalClose}
        onSubmit={onSubmit}
        isLoading={isLoading}
        handleNestedModalIsOpenedChange={handleNestedModalIsOpenedChange}
      />
      <ErrorModal
        errorMessage={data?.error?.message}
        description={data?.error?.details}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />
    </>
  );
};

export default ProfileInformationAvatarSettings;
