import { PropsWithChildren } from 'react';

import { NO_DATA } from 'constants/common';
import { cn } from 'utils/global';

interface Props {
  className?: string;
  colSpan?: number;
  dataCy?: string;
}

const TableData = ({ children, className, colSpan, dataCy }: PropsWithChildren<Props>) => {
  return (
    <td
      data-cy={dataCy}
      colSpan={colSpan}
      className={cn('h-11 px-3 py-0 !text-bodyMd text-neutral-700 dark:text-neutral-300 break-words', className)}
    >
      {children || NO_DATA}
    </td>
  );
};

export default TableData;
