import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { cn } from 'utils/global';

interface Props {
  totalCount?: number;
  className?: string;
}

const TotalCount: FC<Props> = ({ totalCount, className }) => {
  const { t } = useTranslation();

  return (
    <div
      data-cy="pagination-total-count"
      className={cn(`text-bodyMd text-neutral-700 dark:text-neutral-300`, className)}
    >
      {t('TotalRecordsCount', { 0: totalCount || 0 })}
    </div>
  );
};
export default TotalCount;
