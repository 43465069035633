import { useEffect, useState } from 'react';

import { useGetUserConfigQuery } from 'apis/user.api';
import { ThemeConfig } from 'interfaces/user.interface';

const useTheme = () => {
  const response = useGetUserConfigQuery();
  const currentTheme = response.data?.result?.setting.values['App.UserThemeName'];

  const getInitialTheme = () => {
    const isDark = document.documentElement.classList.contains('dark');
    return isDark ? ThemeConfig.dark : ThemeConfig.light;
  };

  const [theme, setTheme] = useState<ThemeConfig>(getInitialTheme);

  useEffect(() => {
    if (!process.env.STORYBOOK && currentTheme) {
      setTheme(ThemeConfig.dark === currentTheme ? ThemeConfig.dark : ThemeConfig.light);
    }
  }, [currentTheme]);

  useEffect(() => {
    if (!process.env.STORYBOOK) {
      return undefined;
    }

    const updateTheme = () => {
      const isDark = document.documentElement.classList.contains('dark');
      setTheme(isDark ? ThemeConfig.dark : ThemeConfig.light);
    };

    updateTheme();

    const observer = new MutationObserver(() => {
      updateTheme();
    });

    observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ['class'],
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  const themeNames = {
    [ThemeConfig.dark]: 'dark',
    [ThemeConfig.light]: 'light',
  };

  return {
    theme,
    themeName: themeNames[theme],
  };
};

export default useTheme;
