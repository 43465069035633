import {
  faCode,
  faEnvelopeOpenText,
  faFileAlt,
  faHouse,
  faImagePortrait,
  faPeopleGroup,
  faMap,
  faMasksTheater,
  faSliders,
  faUsers,
  faKitMedical,
  faUserShield,
  faHouseChimneyUser,
  faMicrochip,
  faBriefcase,
  faLayerGroup,
  faMoneyCheckAlt,
  faIdCard,
  faAddressCard,
  faMoneyBill,
  faListUl,
  faFileInvoice,
  faSquarePen,
  faInbox,
  faShare,
} from '@fortawesome/free-solid-svg-icons';

import { LinkItem, SubmenuItem } from 'interfaces/routes.interfaces';
import { Permissions } from 'interfaces/user.interface';

export const ROUTES = {
  notFound: '/*',
  default: '/',
  home: '/home',
  signIn: '/sign-in',
  signUp: '/sign-up',
  forgotPassword: '/forgot-password',
  activateEmail: '/activate-email',
  resetPassword: '/reset-password',
  dashboard: '/dashboard',
  overview: '/overview',
  managementPage: '/managementPage',
  confirmEmail: '/confirm-email',
  twoFactorAuthenticator: '/two-factor/authenticator',
  twoFactorSms: '/two-factor/sms',
  roles: '/roles',
  users: '/users',
  tenants: '/tenants',
  reporting: '/management/reporting',
  tenantUsers: '/tenants/:tenantId/users',
  tenantRoles: '/tenants/:tenantId/roles',
  tenantDetails: '/tenants/:id/details',
  subscriptions: '/subscriptions',
  auditLogs: '/audit-logs',
  termsAndConditions: '/terms-and-conditions',
  privacyPolicy: '/privacy-policy',
  emailTemplates: '/email-templates',
  createEmailTemplate: `/email-templates/create`,
  sendEmailTemplate: `/email-templates/send`,
  editEmailTemplate: `/email-templates/:emailTemplateId/edit`,
  sendEmailTemplateById: `/email-templates/:emailTemplateId/send`,
  cloneEmailTemplate: `/email-templates/:emailTemplateId/clone`,
  emailHistory: '/email-history',
  samples: '/samples',
  supportTickets: '/support-tickets',
  supportTicketsChat: `/support-tickets/:ticketId/chat`,
  gdprAdmin: '/forget-tenant-requests',
  gdprTenant: '/forget-user-requests',
  organizationUnits: '/organization-units',
  supportRequests: '/support-requests',
  tenantDetailsPage: '/tenant-details',
  invoices: '/invoices',
  invoiceDetailsPage: '/invoice-details/:invoiceId',
  companiesHouse: '/app/integrations/companies-house',
  trueLayer: '/app/integrations/truelayer',
  trueLayerAccountDetails: '/app/integrations/truelayer/accounts/:accountId',
  trueLayerCardDetails: '/app/integrations/truelayer/cards/:cardId',
  goCardless: '/app/integrations/go-cardless',
  xero: '/app/integrations/xero',
  sage: '/app/integrations/sage',
  sageInvoiceDetails: '/app/integrations/sage/invoices/:invoiceId',
  sageContactDetails: '/app/integrations/sage/contacts/:contactId',
  quickBooks: '/app/integrations/quickbooks',
  quickBooksCallback: '/app/integrations/quickbooks-callback',
  payPal: '/app/integrations/paypal',
  codatAccounting: '/app/integrations/codat-accounting',
  customerDetailsPage: '/app/integrations/quickbooks/customers/:customerId',
  invoiceQuickBooksDetailsPage: '/app/integrations/quickbooks/invoices/:invoiceId',
  typlessDocumentTypesPage: '/app/integrations/typless/document-types',
  typlessTrainDocumentPage: '/app/integrations/typless/train-document/:documentId',
  typlessDocumentParsingPage: '/app/integrations/typless/document-parsing',
  osAuditLogs: '/app/integrations/os-audit-logs',
  docusign: '/app/integrations/docusign',
  docusignNewDocument: '/app/integrations/docusign/new-document',
  cypressTestsPage: '/cypress-tests-page',
  xeroCallback: '/app/integrations/xero/callback',
  tenantSubscription: '/tenant-subscription',
  subscriptionSelect: '/tenant-subscription/select',
  subscriptionUpgrade: '/tenant-subscription/upgrade',
  subscriptionPurchase: '/tenant-subscription/purchase',
  subscriptionStripePurchase: '/tenant-subscription/stripe-purchase',
  subscriptionPaymentCompleted: '/tenant-subscription/payment-completed',
  subscriptionPaymentNotCompleted: '/tenant-subscription/stripe-cancel-services',
  storybook: 'https://storybook.easygo.evolved-innovations.co.uk/',
};

export const SIDEBAR_ADMIN_SUBMENU_LINKS: SubmenuItem[] = [
  {
    title: 'Roles',
    path: ROUTES.roles,
    icon: faMasksTheater,
    dataCy: 'roles-item',
    permissionTag: Permissions.PAGES_HOST_TENANTS_ROLES,
  },
  { title: 'Users', path: ROUTES.users, icon: faUsers, permissionTag: Permissions.PAGES_HOST_TENANTS_USERS },
  {
    title: 'Subscriptions',
    path: ROUTES.subscriptions,
    icon: faPeopleGroup,
    permissionTag: Permissions.PAGES_HOST_SUBSCRIPTIONS,
  },
  {
    title: 'Tenants',
    path: ROUTES.tenants,
    icon: faImagePortrait,
    permissionTag: Permissions.PAGES_HOST_TENANTS,
  },
  // {
  //   title: 'Reporting',
  //   path: ROUTES.reporting,
  //   icon: faChartLine,
  //   permissionTag: Permissions.PAGES_HOST_REPORTING,
  // },
  //TODO: Reporting not completed for Angular side
  {
    title: 'EmailTemplates',
    path: ROUTES.emailTemplates,
    icon: faEnvelopeOpenText,
    permissionTag: Permissions.PAGES_HOST_EMAIL_TEMPLATES,
  },
  {
    title: 'EmailHistory',
    path: ROUTES.emailHistory,
    icon: faInbox,
    permissionTag: Permissions.PAGES_HOST_EMAIL_HISTORY,
  },
  { title: 'AuditLogs', path: ROUTES.auditLogs, icon: faFileAlt, permissionTag: Permissions.PAGES_AUDIT_LOGS },
  {
    title: 'GDPR',
    path: ROUTES.gdprAdmin,
    icon: faUserShield,
    permissionTag: Permissions.PAGES_HOST_FORGET_TENANT_REQUEST_MANAGE,
  },
];

export const SIDEBAR_ADMIN_LINKS: LinkItem[] = [
  {
    title: 'HomePage',
    path: ROUTES.dashboard,
    icon: faHouse,
    dataCy: 'home-page-item',
    permissionTag: Permissions.PAGES_HOST_DASHBOARD,
  },
  {
    title: 'Administration',
    icon: faSliders,
    dropdownIcon: true,
    submenu: SIDEBAR_ADMIN_SUBMENU_LINKS,
    dataCy: 'administration-item',
  },
  {
    title: 'SupportTickets',
    path: ROUTES.supportTickets,
    icon: faKitMedical,
    dataCy: 'support-tickets-page-item',
    permissionTag: Permissions.PAGES_HOST_SUPPORT_RESPONSE,
  },
  {
    title: 'SampleComponents',
    path: ROUTES.samples,
    icon: faCode,
    dataCy: 'samples-page-item',
  },
  {
    title: 'Storybook',
    path: ROUTES.storybook,
    icon: faShare,
    dataCy: 'storybook-page-item',
  },
];

export const SIDEBAR_TENANT_ADMINISTRATION_SUBMENU_LINKS: SubmenuItem[] = [
  {
    title: 'Roles',
    path: ROUTES.roles,
    icon: faMasksTheater,
    dataCy: 'roles-item',
    permissionTag: Permissions.PAGES_ROLES,
  },
  { title: 'Users', path: ROUTES.users, icon: faUsers, permissionTag: Permissions.PAGES_USERS },
  {
    title: 'Subscriptions',
    path: ROUTES.tenantSubscription,
    icon: faPeopleGroup,
    permissionTag: Permissions.PAGES_TENANT_SUBSCRIPTION_MANAGEMENT,
  },
  {
    title: 'OrganizationUnits',
    path: ROUTES.organizationUnits,
    icon: faMap,
    dataCy: 'organization-units-page-tenant-item',
    permissionTag: Permissions.PAGES_TENANT_ORGANIZATION_UNITS,
  },
  { title: 'AuditLogs', path: ROUTES.auditLogs, icon: faFileAlt, permissionTag: Permissions.PAGES_AUDIT_LOGS },
  {
    title: 'GDPR',
    path: ROUTES.gdprTenant,
    icon: faUserShield,
    permissionTag: Permissions.PAGES_TENANT_FORGET_USER_REQUEST_MANAGE,
  },
];

export const SIDEBAR_TYPLESS_SUBMENU_LINKS: SubmenuItem[] = [
  {
    title: 'DocumentTypes',
    path: ROUTES.typlessDocumentTypesPage,
    icon: faListUl,
    dataCy: 'typless-document-types-item',
    permissionTag: Permissions.PAGES_TENANT_INTEGRATIONS_TYPELESS,
  },
  {
    title: 'DocumentParsing',
    path: ROUTES.typlessDocumentParsingPage,
    icon: faFileInvoice,
    dataCy: 'typless-document-parsing-item',
    permissionTag: Permissions.PAGES_TENANT_INTEGRATIONS_TYPELESS,
  },
];

export const SIDEBAR_INTEGRATIONS_SUBMENU_LINKS: SubmenuItem[] = [
  {
    title: 'CompaniesHouse',
    path: ROUTES.companiesHouse,
    icon: faBriefcase,
    dataCy: 'companies-house-item',
    permissionTag: Permissions.PAGES_TENANT_INTEGRATIONS,
  },
  {
    title: 'GoCardless',
    path: ROUTES.goCardless,
    icon: faMoneyCheckAlt,
    dataCy: 'goCardless-item',
    permissionTag: Permissions.PAGES_TENANT_INTEGRATIONS_GOCARDLESS,
  },
  {
    title: 'TrueLayer',
    path: ROUTES.trueLayer,
    icon: faLayerGroup,
    dataCy: 'truelayer-item',
    permissionTag: Permissions.PAGES_TENANT_INTEGRATIONS_TRUELAYER,
  },
  {
    title: 'Xero',
    path: ROUTES.xero,
    icon: faIdCard,
    dataCy: 'xero-item',
    permissionTag: Permissions.PAGES_TENANT_INTEGRATIONS_XERO,
  },
  {
    title: 'CodatAccounting',
    path: ROUTES.codatAccounting,
    icon: faAddressCard,
    dataCy: 'codat-accounting-item',
    permissionTag: Permissions.PAGES_TENANT_INTEGRATIONS_CODAT_ACCOUNTING,
  },
  {
    title: 'Opensearch',
    path: ROUTES.osAuditLogs,
    icon: faFileAlt,
    dataCy: 'os-audit-logs-item',
    permissionTag: Permissions.PAGES_AUDIT_LOGS,
  },
  {
    title: 'Docusign',
    path: ROUTES.docusign,
    icon: faSquarePen,
    dataCy: 'docusign-item',
    permissionTag: Permissions.PAGES_TENANT_INTEGRATIONS_DOCUSIGN,
  },
  {
    title: 'Typless',
    icon: faFileAlt,
    dropdownIcon: true,
    dataCy: 'typless-tenant-item',
    submenu: SIDEBAR_TYPLESS_SUBMENU_LINKS,
    permissionTag: Permissions.PAGES_TENANT_INTEGRATIONS_TYPELESS,
  },
  {
    title: 'QuickBooks',
    path: ROUTES.quickBooks,
    icon: faAddressCard,
    dataCy: 'quickBooks-item',
    permissionTag: Permissions.PAGES_TENANT_INTEGRATIONS_QUICKBOOKS,
  },
  {
    title: 'PayPal',
    path: ROUTES.payPal,
    icon: faMoneyBill,
    dataCy: 'paypal-item',
    permissionTag: Permissions.PAGES_TENANT_INTEGRATIONS_PAYPAL,
  },
  {
    title: 'Sage',
    path: ROUTES.sage,
    icon: faAddressCard,
    dataCy: 'sage-item',
    permissionTag: Permissions.PAGES_TENANT_INTEGRATIONS_SAGE,
  },
];

export const SIDEBAR_TENANT_LINKS: LinkItem[] = [
  {
    title: 'HomePage',
    path: ROUTES.dashboard,
    icon: faHouse,
    dataCy: 'home-page-tenant-item',
    permissionTag: Permissions.PAGES_TENANT_DASHBOARD,
  },
  {
    title: 'TenantDetails',
    path: ROUTES.tenantDetailsPage,
    icon: faHouseChimneyUser,
    dataCy: 'tenant-details-tenant-item',
    permissionTag: Permissions.PAGES_TENANT_TENANT_DETAILS,
  },
  {
    title: 'Invoices',
    path: ROUTES.invoices,
    icon: faFileAlt,
    dataCy: 'invoices-item',
    permissionTag: Permissions.PAGES_TENANT_INVOICES,
  },
  {
    title: 'Administration',
    icon: faSliders,
    dropdownIcon: true,
    dataCy: 'administration-tenant-item',
    submenu: SIDEBAR_TENANT_ADMINISTRATION_SUBMENU_LINKS,
    permissionTag: [
      Permissions.PAGES_ROLES,
      Permissions.PAGES_USERS,
      Permissions.PAGES_TENANT_SUBSCRIPTION_MANAGEMENT,
      Permissions.PAGES_AUDIT_LOGS,
      Permissions.PAGES_TENANT_FORGET_USER_REQUEST_MANAGE,
      Permissions.PAGES_TENANT_ORGANIZATION_UNITS,
    ],
  },
  {
    title: 'SupportRequests',
    path: ROUTES.supportRequests,
    icon: faKitMedical,
    dataCy: 'support-requests-page-tenant-item',
    permissionTag: Permissions.PAGES_TENANT_SUPPORT_REQUEST,
  },
  {
    title: 'Integrations',
    icon: faMicrochip,
    dropdownIcon: true,
    dataCy: 'integrations-tenant-item',
    submenu: SIDEBAR_INTEGRATIONS_SUBMENU_LINKS,
    permissionTag: [
      Permissions.PAGES_TENANT_INTEGRATIONS,
      Permissions.PAGES_TENANT_INTEGRATIONS_CODAT_ACCOUNTING,
      Permissions.PAGES_TENANT_INTEGRATIONS_CODAT_OPEN_BANKING,
      Permissions.PAGES_TENANT_INTEGRATIONS_DOCUSIGN,
      Permissions.PAGES_TENANT_INTEGRATIONS_GOCARDLESS,
      Permissions.PAGES_TENANT_INTEGRATIONS_NIMBLA,
      Permissions.PAGES_TENANT_INTEGRATIONS_PAYPAL,
      Permissions.PAGES_TENANT_INTEGRATIONS_QUICKBOOKS,
      Permissions.PAGES_TENANT_INTEGRATIONS_SAGE,
      Permissions.PAGES_TENANT_INTEGRATIONS_TRUELAYER,
      Permissions.PAGES_TENANT_INTEGRATIONS_TYPELESS,
      Permissions.PAGES_TENANT_INTEGRATIONS_XERO,
    ],
  },
  {
    title: 'SampleComponents',
    path: ROUTES.samples,
    icon: faCode,
    dataCy: 'samples-page-tenant-item',
  },
];
