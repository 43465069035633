import { FieldTypes } from 'interfaces/shared.interface';
import { cn } from 'utils/global';

interface Props {
  isEnabled: boolean;
  onToggle: VoidFunction;
  dataCy?: string;
  disabled?: boolean;
}
const ToggleSwitch = ({ onToggle, isEnabled, dataCy, disabled }: Props) => {
  return (
    <div className="flex">
      <label
        htmlFor="toggle"
        className={cn('flex items-center cursor-pointer rounded-3xl', {
          'bg-primary-500 hover:bg-primary-600': isEnabled,
          'bg-neutral-300 hover:bg-neutral-400 dark:bg-neutral-700 dark:hover:bg-neutral-800': !isEnabled,
          'opacity-50 cursor-not-allowed': disabled,
          'hover:bg-primary-500': disabled && isEnabled,
          'hover:bg-neutral-300 dark:hover:bg-neutral-700': disabled && !isEnabled,
        })}
      >
        <div className="relative" data-cy={dataCy}>
          <input
            id="toggle"
            type={FieldTypes.CHECKBOX}
            className="sr-only"
            checked={isEnabled}
            disabled={disabled}
            onChange={onToggle}
          />
          <div className="block w-[2.75rem] h-6" />
          <div
            className={cn('transition duration-200 absolute left-1 top-1 bg-white-00 w-4 h-4 rounded-full', {
              'transform translate-x-5': isEnabled,
            })}
          />
        </div>
      </label>
    </div>
  );
};

export default ToggleSwitch;
