import { INodeRendererProps } from 'react-accessible-treeview';
import { Control, FieldValues, UseFormRegister } from 'react-hook-form';

import { FieldTypes } from 'interfaces/shared.interface';
import { SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES } from 'interfaces/subscriptions-admin.interface';
import { InputNumberFormat } from 'shared-components';
import TreeViewItem from 'shared-components/TreeViewItem';

const ELEMENTS_WITH_INPUT_IDS = [SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.MAX_USERS_COUNT];

interface NodeProps extends INodeRendererProps {
  register: UseFormRegister<FieldValues>;
  control: Control<FieldValues>;
}

const NodeRenderer: React.FC<NodeProps> = (props) => {
  const { register, control } = props;
  const renderWithInput = ELEMENTS_WITH_INPUT_IDS.some((id) => id === props.element.id);

  if (renderWithInput) {
    return (
      <TreeViewItem {...props} isSelected>
        <InputNumberFormat
          {...register(SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.MAX_USERS_COUNT)}
          dataCy="max-users-count-field"
          type={FieldTypes.NUMBER}
          inputClassName="max-h-7 dark:hover:none dark:border-darkBlue"
          className="ml-2"
          control={control}
          isShownArrowButtons
          fixedDecimalLength={0}
          arrowButtonArrowClassName="w-4 dark:bg-darkBlue"
          arrowButtonBodyClassName="scale-y-[0.7] w-4"
        />
      </TreeViewItem>
    );
  }

  return <TreeViewItem {...props} />;
};

NodeRenderer.displayName = 'NodeRenderer';

export const renderNode =
  // eslint-disable-next-line react/display-name
  (register: UseFormRegister<FieldValues>, control: Control<FieldValues>) => (props: INodeRendererProps) => {
    return <NodeRenderer {...props} register={register} control={control} />;
  };
