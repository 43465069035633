import { FieldTypes } from 'interfaces/shared.interface';

export enum ButtonVariants {
  PRIMARY = 'primary',
  OUTLINE = 'outline',
  PLAIN = 'plain',
  DESTRUCTIVE = 'destructive',
  DISABLED = 'disabled',
}

export enum ButtonSizeVariants {
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
}

export enum BoxVariants {
  MAIN = 'main',
  TRANSPARENT = 'transparent',
  ROUNDED = 'rounded',
}

export const LANGUAGE_VARIANTS = {
  UA: 'uk-UA',
  UK: 'en-GB',
  USA: 'en',
};

export const ButtonVariantStyles: { [key in ButtonVariants]: string } = {
  [ButtonVariants.PRIMARY]:
    'text-sm text-white-00 bg-primary-500 hover:bg-primary-600 active:bg-primary-700 rounded-md',
  [ButtonVariants.OUTLINE]:
    'text-sm text-neutral-700 dark:text-neutral-300 hover:bg-neutral-50 dark:hover:bg-neutral-900 active:bg-neutral-100 dark:active:bg-neutral-900 border border-neutral-200 dark:border-neutral-200 rounded-md px-4',
  [ButtonVariants.PLAIN]:
    'text-sm text-neutral-700 dark:text-neutral-300 hover:bg-neutral-50 dark:hover:bg-neutral-900 active:bg-neutral-100 dark:active:bg-neutral-900 rounded-md px-4',
  [ButtonVariants.DESTRUCTIVE]:
    'text-sm text-white-00 bg-red-500 hover:bg-red-600 active:bg-red-700 rounded-md py-2.5 px-4',
  [ButtonVariants.DISABLED]: 'text-sm text-neutral-400 bg-neutral-100 rounded-md cursor-not-allowed px-4',
};

export const ButtonSizeVariantsStyles: { [key in ButtonSizeVariants]: string } = {
  [ButtonSizeVariants.SM]: 'py-0.5 px-2 h-6',
  [ButtonSizeVariants.MD]: 'py-1.5 px-3 h-8',
  [ButtonSizeVariants.LG]: 'py-2.5 px-4 h-10',
};

export const BoxVariantsStyles: { [key in BoxVariants]: string } = {
  [BoxVariants.MAIN]: 'bg-white dark:bg-darkBlue shadow',
  [BoxVariants.TRANSPARENT]: 'border border-gray6 dark:border-darkBlue4 bg-white-00 dark:bg-black-900',
  [BoxVariants.ROUNDED]: 'bg-white dark:bg-darkBlue shadow-centerLight rounded-md',
};

export enum LOCAL_STORAGE_KEYS {
  AUTH_TOKEN = 'auth_token',
}

export enum COOKIE_KEYS {
  AUTH_TOKEN = 'Abp.AuthToken',
  ENC_AUTH_TOKEN = 'enc_auth_token',
  LOCALIZATION_CULTURE_NAME = 'Abp.Localization.CultureName',
  STORYBOOK_AUTH_TOKEN = 'storybook_auth_token',
}

export enum BASE_FILTER_FIELDS {
  SEARCH_FIELD = 'filter',
}

export enum BASE_TEXT_AREA_FIELDS {
  TEXT_AREA_FIELD = 'textArea',
}

export enum BASE_PERMISSIONS_FROM_FIELD_NAME {
  PERMISSIONS = 'permissions',
}

export const FORM_INPUTS_WITHOUT_REFS = [FieldTypes.SELECT, FieldTypes.NUMBER, FieldTypes.DATE];

export const NO_DATA = '-';
