import { createAction } from '@reduxjs/toolkit';

import { COOKIE_KEYS } from 'constants/common';
import { SetAuthPayload, SignInPayload } from 'interfaces/auth.interface';
import { getAuthCookie } from 'utils/auth';

export const getAuthState = createAction('GET_AUTH_STATE', () => {
  const authToken = getAuthCookie(COOKIE_KEYS.AUTH_TOKEN);
  const encAuthToken = getAuthCookie(COOKIE_KEYS.ENC_AUTH_TOKEN);
  const storybookAuthToken = getAuthCookie(COOKIE_KEYS.STORYBOOK_AUTH_TOKEN);

  return {
    payload: {
      isAuth: !!authToken && !!encAuthToken,
      accessToken: authToken,
      encAuthToken: encAuthToken,
      storybookAuthToken: storybookAuthToken,
    } as SetAuthPayload,
  };
});

export const getAuthCredentials = createAction('GET_AUTH_CREDENTIALS', (payload) => {
  return {
    payload: { ...payload } as SignInPayload,
  };
});

export const userLogout = createAction('LOGOUT');
