export enum TableVariants {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

export const TableVariantsStyles: Record<
  TableVariants,
  { tableClassName: string; rowClassName: string; headClassName: string }
> = {
  [TableVariants.PRIMARY]: {
    tableClassName: 'bg-white-00 dark:bg-black-900',
    headClassName: 'p-3 ',
    rowClassName: 'border-neutral-200 dark:border-neutral-800 border-b dark:text-white dark:bg-black-900',
  },
  [TableVariants.SECONDARY]: {
    tableClassName: 'bg-transparent',
    headClassName: '!border-x-0 border-y !border-lightGray4 text-sm font-normal',
    rowClassName: '[&_td]:p-2 dark:text-white [&_td]:border-0 [&_td]:border-b [&_td]:last:border-b-0',
  },
};
