import { ChangeEvent, DragEvent, useRef, useState } from 'react';

import { faFile, faFilePdf } from '@fortawesome/free-regular-svg-icons';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import DragAndDropSVG from 'assets/svg/dragAndDropImage.svg';
import { ButtonVariants } from 'constants/common';
import { cn } from 'utils/global';

import Button from './button/Button';

interface Props {
  handleUpload: (files: FileList) => void;
  handleRemove?: () => void;
  buttonText: string;
  multiple?: boolean;
  className?: string;
  fileType?: string | string[];
}

const DragAndDropFileUploader = ({
  handleUpload,
  handleRemove,
  multiple,
  buttonText = 'BrowseFiles',
  className,
  fileType = 'pdf',
}: Props) => {
  const { t } = useTranslation();
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const [fileName, setFileName] = useState<string | null>(null);
  const [isDragOver, setIsDragOver] = useState(false);
  const dropZoneRef = useRef<HTMLDivElement>(null);
  const [isIncorrectFileSelected, setIsIncorrectFileSelected] = useState(false);

  const handleButtonClick = () => {
    if (!hiddenFileInput.current) {
      return;
    }
    hiddenFileInput.current.click();
  };

  const validateFileType = (file: File) => {
    const acceptTypes = Array.isArray(fileType) ? fileType : [fileType];
    return acceptTypes.some((type) => file.type === `application/${type}` || file.name.endsWith(`.${type}`));
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files?.length) {
      return;
    }

    const uploadedFiles = event.target.files[0];
    if (!validateFileType(uploadedFiles)) {
      event.target.value = '';
      return;
    }

    handleUpload(event.target.files);
    setFileName(uploadedFiles.name);
  };

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragOver(false);
    if (!event.dataTransfer.files.length) {
      return;
    }
    const droppedFile = event.dataTransfer.files[0];
    if (!validateFileType(droppedFile)) {
      setIsIncorrectFileSelected(true);
      return;
    }
    handleUpload(event.dataTransfer.files);
    setFileName(droppedFile.name);
  };

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragOver(true);
  };

  const handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragOver(false);
  };

  const handleRemoveFile = () => {
    setFileName('');
    if (hiddenFileInput.current) {
      hiddenFileInput.current.value = '';
    }
    if (handleRemove) {
      handleRemove();
    }
  };

  let acceptFileType = '';
  if (typeof fileType === 'string') {
    acceptFileType = `.${fileType}`;
  } else if (Array.isArray(fileType)) {
    acceptFileType = fileType.map((type) => `.${type}`).join(',');
  }

  return (
    <div
      ref={dropZoneRef}
      className={cn(
        'flex flex-col justify-center items-center cursor-pointer w-full h-[18.75rem] rounded-[1.25rem] border-dashed border-[2px] text-center py-8 px-4 transition-colors relative',
        {
          'border-primary-500 bg-primary-50': !isDragOver,
          'border-primary-300 bg-primary-100': isDragOver,
        },
        className
      )}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
    >
      {fileName && (
        <FontAwesomeIcon
          icon={faXmark}
          className=" text-primary-500 h-5 absolute right-3 top-3"
          onClick={handleRemoveFile}
        />
      )}
      {!multiple && fileName ? (
        <div className="flex flex-col items-center">
          {fileName.endsWith('.pdf') ? (
            <FontAwesomeIcon icon={faFilePdf} className="text-primary-300 h-20 mb-2" />
          ) : (
            <FontAwesomeIcon icon={faFile} className="text-primary-300 h-20 mb-2" />
          )}
          <p className="text-bodyMd">{fileName}</p>
        </div>
      ) : (
        <>
          <div className="flex flex-col items-center justify-center">
            <img className="mb-3.5" src={DragAndDropSVG} alt="dragAndDropImage" />
            <p className="text-black text-bodyMd">{t('DragYourFileToStartUploading')}</p>
            <p className="text-black text-bodyMd">{t('OR')}</p>
          </div>

          <Button
            data-cy="upload-file-button"
            className={cn('!text-primary-500 !bg-transparent text-bodyMd-underline mt-[-0.75rem]', className)}
            type="button"
            onClick={handleButtonClick}
            variant={ButtonVariants.PLAIN}
          >
            {t(buttonText)}
          </Button>
          {isIncorrectFileSelected && (
            <p className="text-error mt-2">{t('TheDocumentMustBeOfType', { 0: fileType })}</p>
          )}
          <input
            className="hidden"
            ref={hiddenFileInput}
            type="file"
            onChange={handleInputChange}
            multiple={multiple}
            accept={acceptFileType}
          />
        </>
      )}
    </div>
  );
};

export default DragAndDropFileUploader;
