import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { usePostDisconnectDocusignAccountMutation } from 'apis/docusign.api';
import { ButtonVariants } from 'constants/common';
import { useOpen } from 'hooks/useOpen';
import { CommonError } from 'interfaces/shared.interface';
import { DropdownMenu, ErrorModal } from 'shared-components';

import CreateRequestModal from './CreateRequestModal';

interface Props {
  triggerRefetch: () => void;
}

const DocusignActionsButton = ({ triggerRefetch }: Props) => {
  const { t } = useTranslation();
  const [isOpen, handleOpen, handleClose] = useOpen();
  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();
  const [disconnectDocusign, { error: disconnectDocusignAccountError }] = usePostDisconnectDocusignAccountMutation();
  const errorDisconnectDocusignData = (disconnectDocusignAccountError as CommonError)?.data || {};
  const errorData = errorDisconnectDocusignData;

  const handleDisconnectDocusign = async () => {
    try {
      await disconnectDocusign().unwrap();
      triggerRefetch();
    } catch (error) {
      handleOpenErrorModal();
    }
  };

  const menuActions = [
    {
      dataCy: 'menu-item-create-button',
      name: 'Create',
      actionOnClick: handleOpen,
    },
    {
      dataCy: 'menu-item-disconnect-docusign-button',
      name: 'DisconnectDocusign',
      actionOnClick: handleDisconnectDocusign,
    },
  ];

  return (
    <>
      <ErrorModal
        errorMessage={errorData?.error?.message}
        description={errorData?.error?.details}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />
      <DropdownMenu
        elements={menuActions}
        buttonVariant={ButtonVariants.PRIMARY}
        dataCy="docusign-actions"
        className="flex"
      >
        {t('Actions')}
        <FontAwesomeIcon icon={faAngleDown} className="ml-2" />
      </DropdownMenu>
      <CreateRequestModal isOpen={isOpen} handleClose={handleClose} />
    </>
  );
};

export default DocusignActionsButton;
