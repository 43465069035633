import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { useAdminGetRecentTenantsDataQuery } from 'apis';
import { ButtonVariants } from 'constants/common';
import { RECENT_TENANTS_COLUMNS } from 'constants/recent-tenants';
import { ROUTES } from 'constants/routes';
import { Box, LinkButton, LoadingOverlay, Table } from 'shared-components';

import { RecentTenantsTableRow } from './RecentTenantsTableRow';

export const RecentTenants = () => {
  const { t } = useTranslation();

  const { data: recentTenantsData, isLoading, isFetching } = useAdminGetRecentTenantsDataQuery();
  const recentTenants = recentTenantsData?.result.recentTenants;

  return (
    <Box
      dataCy="recent-tenants-widget"
      className="relative flex flex-col gap-4 h-full overflow-auto"
      title="WidgetRecentTenants"
      titleClassName="mb-0"
      headerClassName="text-blue2 flex-row-reverse justify-end items-baseline gap-2"
      headerWrapperClassName="flex flex-col md:flex-row items-baseline gap-2"
      customHeader={<FontAwesomeIcon icon={faUserPlus} className="w-5 h-4 self-start" />}
      customSubHeader={<p className="text-xs text-gray2">{t('TenantsRegisteredInDays', { 0: 10, 1: 7 })}</p>}
    >
      <LoadingOverlay isLoading={isLoading || isFetching} />
      <Table
        wrapperClassName="flex-1"
        className="mt-7"
        dataCy="expiring-tenants-table"
        columns={RECENT_TENANTS_COLUMNS}
        isTableEmpty={!recentTenants?.length}
        isLoading={isLoading}
      >
        {recentTenants?.map((tenant) => <RecentTenantsTableRow key={tenant.id} tenant={tenant} />)}
      </Table>
      <LinkButton
        className="w-fit self-end"
        variant={ButtonVariants.OUTLINE}
        to={ROUTES.tenants}
        data-cy="recent-tenants-see-all"
      >
        {t('SeeAllRecords')}
      </LinkButton>
    </Box>
  );
};
