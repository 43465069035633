import { createSlice } from '@reduxjs/toolkit';

import { AuthState } from 'interfaces/auth.interface';

import { getAuthCredentialsReducer, getAuthorizationReducer, getLogoutReducer, setIsAuthReducer } from './reducers';

const initialState: AuthState = {
  accessToken: null,
  encAuthToken: null,
  storybookAuthToken: null,
  isAuth: null,
  authCredentials: null,
  userId: null,
  twoFactorAuthenticationType: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getLogoutReducer(builder);
    setIsAuthReducer(builder);
    getAuthCredentialsReducer(builder);
    getAuthorizationReducer(builder);
  },
});

export default authSlice.reducer;
