import { ButtonHTMLAttributes, FC, ReactNode, MouseEvent } from 'react';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { ButtonSizeVariants, ButtonSizeVariantsStyles, ButtonVariants, ButtonVariantStyles } from 'constants/common';
import useTheme from 'hooks/useTheme';
import { ThemeConfig } from 'interfaces/user.interface';
import { cn } from 'utils/global';

export interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  isLoading?: boolean;
  disabled?: boolean;
  className?: string;
  onClick?: VoidFunction | ((e: MouseEvent<HTMLButtonElement>) => void);
  variant?: ButtonVariants;
  dataCy?: string;
  hidden?: boolean;
  icon?: IconProp;
  size?: ButtonSizeVariants;
}

const themeStyles = {
  [ThemeConfig.light]: ButtonVariantStyles[ButtonVariants.PRIMARY],
  [ThemeConfig.dark]: ButtonVariantStyles[ButtonVariants.OUTLINE],
};

function getButtonStyles(
  isDisabled: boolean | undefined,
  variant: ButtonVariants,
  theme: ThemeConfig,
  size: ButtonSizeVariants
) {
  const styles = [];

  if (isDisabled) {
    styles.push(ButtonVariantStyles[ButtonVariants.DISABLED]);
  } else if (variant) {
    styles.push(ButtonVariantStyles[variant]);
  } else {
    styles.push(themeStyles[theme]);
  }

  if (size) {
    styles.push(ButtonSizeVariantsStyles[size]);
  }

  return styles.join(' ');
}

const Button: FC<Props> = ({
  children,
  variant = ButtonVariants.PRIMARY,
  isLoading,
  disabled,
  className,
  onClick,
  dataCy,
  hidden,
  icon,
  size = ButtonSizeVariants.LG,
  ...props
}) => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const isDisabled = disabled || isLoading;
  const styles = getButtonStyles(isDisabled, variant, theme, size);

  if (hidden) {
    return null;
  }

  return isLoading ? (
    <button
      disabled
      className={cn('flex items-center justify-center', styles, ButtonSizeVariantsStyles[size], className)}
    >
      <FontAwesomeIcon className="animate-spin mr-2 text-white" icon={faSpinner} />
      <div>{t('PleaseWait')}</div>
    </button>
  ) : (
    <button className={cn(styles, className)} disabled={disabled} onClick={onClick} data-cy={dataCy} {...props}>
      {icon && <FontAwesomeIcon className="mr-2" icon={icon} />}
      {children}
    </button>
  );
};

export default Button;
