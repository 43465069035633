import { ChangeEvent, FC, useEffect, useRef } from 'react';

import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Control } from 'react-hook-form';
import { MultiValueProps } from 'react-select';

import { MULTI_VALUE_STYLES } from 'constants/shared-components';
import { OPTION_TYPE, OptionTypeAdvancedFilter } from 'interfaces/integrations/os-audit-logs.interface';

import DateTimePicker from './DateAndTimePicker';

interface MultiValuePropsExtended<OptionTypeAdvancedFilter> extends MultiValueProps<OptionTypeAdvancedFilter, true> {
  inputValues: { [key: string]: string };
  onInputChange: (optionValue: string, value: string) => void;
  type?: string;
  control: Control;
  isDateAndTimePickerOpen: boolean;
  setIsDateAndTimePickerOpen: (isOpen: boolean) => void;
}

const MultiValue: FC<MultiValuePropsExtended<OptionTypeAdvancedFilter>> = ({
  data,
  removeProps,
  innerProps,
  inputValues,
  onInputChange,
  type,
  control,
  isDateAndTimePickerOpen,
  setIsDateAndTimePickerOpen,
}) => {
  const selectedOption = data.value as string;
  const value = inputValues[selectedOption] || '';

  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onInputChange(selectedOption, e.target.value);
  };

  const handleRemove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    onInputChange(selectedOption, '');
    if (removeProps.onClick) {
      removeProps.onClick(e);
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  return (
    <div
      data-cy={`${selectedOption}`}
      className="flex items-center space-x-2 bg-neutral-100 dark:bg-neutral-900 p-1 border-none rounded-md m-1 custom-input text-neutral-600 dark:text-neutral-300 !h-9"
      {...innerProps}
    >
      <span className="text-bodySm-semibold">{data.label}</span>
      {type === OPTION_TYPE.DATE && (
        <DateTimePicker
          dataCy="advanced-filter-date-time-picker"
          isDateAndTimePickerOpen={isDateAndTimePickerOpen}
          setIsDateAndTimePickerOpen={setIsDateAndTimePickerOpen}
          data={data}
          onInputChange={onInputChange}
          inputValues={inputValues}
          control={control}
          className={MULTI_VALUE_STYLES}
        />
      )}
      {type === OPTION_TYPE.LONG ||
      type === OPTION_TYPE.TEXT ||
      type === OPTION_TYPE.KEYWORD ||
      type === OPTION_TYPE.INTEGER ? (
        <input
          data-cy="advanced-filter-input"
          ref={inputRef}
          type="text"
          value={value}
          onChange={handleChange}
          className={MULTI_VALUE_STYLES}
        />
      ) : null}
      <div className="relative cursor-pointer flex" onClick={handleRemove}>
        <FontAwesomeIcon
          className="text-neutral-600 dark:text-neutral-300 hover:!text-primary-500 w-4 h-4"
          icon={faXmark}
        />
      </div>
    </div>
  );
};

export default MultiValue;
