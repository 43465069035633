import { FC } from 'react';

import { faAnglesLeft, faAnglesRight, faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MultiValue, SingleValue } from 'react-select';

import { PAGINATION_SELECT_OPTIONS } from 'constants/global';
import { OptionType } from 'interfaces/shared.interface';
import { Select, TotalCount } from 'shared-components';
import { cn } from 'utils/global';

interface Props {
  totalCount?: number;
  handleSetMaxResultCount: (value: number) => void;
  maxResultCount: number;
  handleSetCurrentPage: (value: number) => void;
  currentPage: number;
}

const SHARED_BUTTON_CLASSES =
  'text-bodyMd-semibold rounded-full py-1 px-4 mx-0.5 hover:bg-neutral-50 dark:hover:bg-neutral-900 rounded-md text-neutral-600 dark:text-neutral-400 hover:text-neutral-800 dark:hover:text-neutral-200 disabled:text-gray3 disabled:hover:bg-transparent disabled:hover:text-gray3 ';

const Pagination: FC<Props> = ({
  totalCount,
  handleSetMaxResultCount,
  maxResultCount,
  handleSetCurrentPage,
  currentPage,
}) => {
  const totalPages = Math.ceil((totalCount || 1) / maxResultCount);
  const firstPageToShow = Math.max(1, currentPage - 4);
  const lastPageToShow = Math.min(totalPages, firstPageToShow + 4);

  const onChangeSelect = (newValue: SingleValue<OptionType> | MultiValue<OptionType>) => {
    if (!newValue) {
      return;
    }

    const { value } = newValue as OptionType;
    handleSetMaxResultCount(Number(value));
  };
  const setCurrent = (page: number) => {
    handleSetCurrentPage(page);
  };

  const pages = Array.from({ length: lastPageToShow - firstPageToShow + 1 }, (_, index) => firstPageToShow + index);

  return (
    <div className="p-2">
      <div className="flex justify-center">
        <div>
          <button
            data-cy="pagination-first-page"
            onClick={() => setCurrent(1)}
            disabled={currentPage === 1}
            className={cn(SHARED_BUTTON_CLASSES, {
              'bg-transparent text-blue3 dark:hover:bg-transparent': currentPage === 1,
              'dark:hover:bg-neutral-900': currentPage !== 1,
            })}
          >
            <FontAwesomeIcon icon={faAnglesLeft} />
          </button>
          <button
            data-cy="pagination-previous-page"
            onClick={() => setCurrent(currentPage - 1)}
            disabled={currentPage === 1}
            className={cn('text-darkGray2', SHARED_BUTTON_CLASSES, {
              'bg-transparent text-blue3 dark:hover:bg-transparent': currentPage === 1,
              'dark:hover:bg-neutral-900': currentPage !== 1,
            })}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
          </button>
          {pages.map((page) => (
            <button
              data-cy="pagination-pages"
              key={page}
              onClick={() => setCurrent(page)}
              className={cn(
                SHARED_BUTTON_CLASSES,
                { 'text-darkGray2 dark:hover:bg-neutral-900': currentPage !== page },
                { 'text-primary-600 dark:text-primary-600 bg-primary-50 dark:bg-primary-50': currentPage === page }
              )}
            >
              {page}
            </button>
          ))}
          <button
            data-cy="pagination-next-page"
            onClick={() => setCurrent(currentPage + 1)}
            disabled={currentPage === totalPages}
            className={cn('text-darkGray2', SHARED_BUTTON_CLASSES, {
              'bg-transparent text-blue3': currentPage === totalPages,
              'dark:hover:bg-neutral-900': currentPage !== totalPages,
            })}
          >
            <FontAwesomeIcon icon={faAngleRight} />
          </button>
          <button
            data-cy="pagination-last-page"
            onClick={() => setCurrent(totalPages)}
            disabled={currentPage === totalPages}
            className={cn('text-darkGray2', SHARED_BUTTON_CLASSES, {
              'bg-transparent text-blue3': currentPage === totalPages,
              'dark:hover:bg-neutral-900': currentPage !== totalPages,
            })}
          >
            <FontAwesomeIcon icon={faAnglesRight} />
          </button>
        </div>
        <div>
          <Select options={PAGINATION_SELECT_OPTIONS} onChange={onChangeSelect} isSearchable={false} />
        </div>
      </div>
      {totalCount !== undefined && <TotalCount totalCount={totalCount} />}
    </div>
  );
};

export default Pagination;
