import { useTranslation } from 'react-i18next';

import { useGetForgetMyTenantRequestDetailsQuery } from 'apis/tenant-details.api';
import { DAY_FORMAT_WITH_SLASHES } from 'constants/global';
import { Button } from 'shared-components';
import { getFormattedDate } from 'utils/dates';

interface Props {
  title?: string;
  dataCy?: string;
  description?: string;
  buttonName?: string;
  onActionClick?: VoidFunction;
}

const TenantRequestToBeForgottenBlock = ({ title, dataCy, description, buttonName, onActionClick }: Props) => {
  const { t } = useTranslation();
  const { data, isLoading, isFetching } = useGetForgetMyTenantRequestDetailsQuery();

  return (
    <div data-cy={dataCy} className="w-full bg-white dark:bg-darkBlue shadow-centerLight rounded-md mb-4 py-8 px-4">
      <div>
        <h2 className="text-lg font-medium">{title}</h2>
      </div>
      <div className="sm:flex justify-between items-center">
        <p className="text-sm">{description}</p>
        {data?.result.isRequested ? (
          <p className="text-sm mt-5 sm:mt-0">
            {`${t('RequestedOn')} ${getFormattedDate(data?.result.dateCreated, DAY_FORMAT_WITH_SLASHES)}`}
          </p>
        ) : (
          <>
            <Button
              data-cy="request-to-be-forgotten-button"
              className="sm:ml-3.5 mt-5 sm:mt-0"
              type="button"
              onClick={onActionClick}
              isLoading={isLoading || isFetching}
            >
              {buttonName}
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default TenantRequestToBeForgottenBlock;
