import { IconDefinition, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { useDeleteOrganizationUnitUserMutation } from 'apis/organization-units.api';
import { ButtonVariants } from 'constants/common';
import { DAY_FORMAT_WITH_SLASHES } from 'constants/global';
import { useOpen } from 'hooks/useOpen';
import { SelectedUnitInfo, SharedMembersData } from 'interfaces/organization-units.interface';
import { CommonError } from 'interfaces/shared.interface';
import { Button, ErrorModal, TableData, TableRow, WarningModal } from 'shared-components';
import { getFormattedDate } from 'utils/dates';
import { successNotify } from 'utils/notifications';

type Props = {
  userData: SharedMembersData;
  selectedUnit: SelectedUnitInfo | null;
  refetchMembers: VoidFunction;
};

const MembersTabRow = ({ userData, selectedUnit, refetchMembers }: Props) => {
  const { t } = useTranslation();

  const [isOpenInfoModal, handleOpenInfoModal, handleCloseInfoModal] = useOpen();
  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();

  const [
    deleteOrganizationUserUnit,
    { isLoading: isDeleteOrganizationUserLoading, error: deleteOrganizationUserError },
  ] = useDeleteOrganizationUnitUserMutation();

  const errorData = (deleteOrganizationUserError as CommonError)?.data || {};

  const { id, name, surname, addedTime } = userData;

  const handleDeleteUnitUser = async () => {
    const userId = id;
    const unitId = selectedUnit?.id;
    if (typeof unitId === 'number') {
      try {
        await deleteOrganizationUserUnit({ userId, unitId }).unwrap();
        refetchMembers();
        handleCloseInfoModal();
        successNotify(t('SuccessfullyDeleted'));
      } catch (error) {
        handleOpenErrorModal();
      }
    }
  };

  return (
    <>
      <ErrorModal
        errorMessage={errorData?.error?.message}
        description={errorData?.error?.details}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />
      <WarningModal
        warningMessage={t('AreYouSure')}
        description={t('RemoveUserFromOuWarningMessage', {
          0: name + ' ' + surname,
          1: selectedUnit?.displayName,
        })}
        isOpen={isOpenInfoModal}
        handleClose={handleCloseInfoModal}
        handleConfirm={handleDeleteUnitUser}
      />

      <TableRow dataCy="members-table-row">
        <TableData>
          <Button
            isLoading={isDeleteOrganizationUserLoading}
            type="button"
            dataCy="delete-member-button"
            variant={ButtonVariants.DESTRUCTIVE}
            onClick={handleOpenInfoModal}
          >
            <FontAwesomeIcon icon={faXmark as IconDefinition} />
          </Button>
        </TableData>
        <TableData>{name + ' ' + surname}</TableData>
        <TableData>{getFormattedDate(addedTime, DAY_FORMAT_WITH_SLASHES)}</TableData>
      </TableRow>
    </>
  );
};

export default MembersTabRow;
