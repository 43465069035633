import { useTranslation } from 'react-i18next';

import { ReactComponent as ArrowLeft } from 'assets/svg/arrow-left-3.svg';
import { ButtonVariants } from 'constants/common';
import { ROUTES } from 'constants/routes';
import { GetCustomers } from 'interfaces/integrations/quickBooks.interface';
import { Box, LabelWithValue, LinkButton, LoadingOverlay } from 'shared-components';

interface Props {
  data: GetCustomers | undefined;
  fetching: boolean;
  isLoading: boolean;
}

const CustomerDetails = ({ data, fetching, isLoading }: Props) => {
  const { t } = useTranslation();
  const noDate = '-';

  const {
    name,
    emailAddress,
    preferredDeliveryMethod,
    firstName,
    primaryPhoneNumber,
    isActive,
    lastName,
    mobileNumber,
    companyName,
    billingAddress,
  } = data || {};

  const labelValueMap: { [key: string]: string | number | boolean | null | undefined } = {
    CustomerName: name,
    EmailAddress: emailAddress,
    PreferredDeliveryMethod: preferredDeliveryMethod,
    FirstName: firstName,
    PrimaryPhoneNumber: primaryPhoneNumber,
    Status: t(isActive ? 'Active' : 'NotActive'),
    LastName: lastName,
    MobileNumber: mobileNumber,
    CompanyName: companyName,
    BillingAddress: billingAddress,
  };

  return (
    <div className="relative">
      <LoadingOverlay isLoading={fetching && !!data} />

      <Box data-cy="invoice-data" className="p-4">
        <LinkButton className="mb-5 w-24" variant={ButtonVariants.OUTLINE} to={ROUTES.quickBooks}>
          <ArrowLeft className="fill-neutral-700 mr-1.5" />
          {t('Back')}
        </LinkButton>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3">
          {Object.entries(labelValueMap).map(([label, value]) => (
            <LabelWithValue
              isLoading={isLoading}
              key={label}
              labelClassName="w-1/2 items-center flex"
              label={label}
              value={value || noDate}
            />
          ))}
        </div>
      </Box>
    </div>
  );
};

export default CustomerDetails;
