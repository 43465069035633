import { useEffect, useState } from 'react';

import { IconDefinition, faPlus } from '@fortawesome/free-solid-svg-icons';
import { startOfDay } from 'date-fns';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import {
  usePostAddDocumentForTrainingMutation,
  usePostParseDocumentMutation,
  usePostStartTrainingMutation,
} from 'apis/typless.apis';
import { ButtonVariants } from 'constants/common';
import { ROUTES } from 'constants/routes';
import { TRAIN_DOCUMENT_TABLE_COLUMNS } from 'constants/typless';
import {
  GetDocumentTypeDetailsResponse,
  ParseDocument,
  ParseDocumentResponse,
  TrainData,
  TrainDocumentTypePayload,
  UploadDocumentPayload,
} from 'interfaces/integrations/typless.interface';
import DocumentDetailsFormInfo from 'page-components/integrations/typless/documentTypes/DocumentDetailsFormInfo';
import TrainDocumentRow from 'page-components/integrations/typless/documentTypes/TrainDocumentRow';
import { Button, Table, PDFViewer } from 'shared-components';
import { successNotify } from 'utils/notifications';

interface Props {
  fileData: { fileUrl: null | string; fileBase64: string; fileName: string };
  data: GetDocumentTypeDetailsResponse | undefined;
  parseData: ParseDocumentResponse | undefined;
  isLoading: boolean;
  error: unknown;
  successfullMessage?: boolean;
  trainDocumentType: ReturnType<typeof usePostAddDocumentForTrainingMutation>[0];
  startTraining: ReturnType<typeof usePostStartTrainingMutation>[0];
  parseDocument: ReturnType<typeof usePostParseDocumentMutation>[0];
  uploadDocument: any;
  uploadDocumentLogic: boolean;
  handleOpenErrorModal: () => void;
  handleFileReset: () => void;
  startTrainingData: any;
  saveButton: string;
  isDocumentTypeSelected: () => void;
}

const TyplessDocumentTypesDetailsBlock = ({
  fileData,
  data,
  parseData,
  isLoading,
  error,
  successfullMessage,
  trainDocumentType,
  startTraining,
  parseDocument,
  uploadDocument,
  uploadDocumentLogic,
  handleOpenErrorModal,
  handleFileReset,
  startTrainingData,
  saveButton,
  isDocumentTypeSelected,
}: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState<Partial<TrainData>>({});
  const [addedRows, setAddedRows] = useState<ParseDocument[]>([]);
  const [serverRows, setServerRows] = useState<ParseDocument[]>([]);
  const [isFormValid, setIsFormValid] = useState(false);
  const [updateTrigger, setUpdateTrigger] = useState(0);

  const documentTypeId = data?.result?.id ?? 0;
  const documentNumberOfTrainings = data?.result?.numberOfTrainings ?? 0;

  useEffect(() => {
    if (error) {
      handleOpenErrorModal();
    }
  }, [error, handleOpenErrorModal]);

  const handleAddRow = () => {
    setAddedRows([...addedRows, { id: uuidv4(), description: '', quantity: 0, price: 0, tax: 0, amount: 0 }]);
    setUpdateTrigger((prev) => prev + 1);
  };

  const handleDeleteRow = (id: string) => {
    const updatedServerRows = serverRows.filter((row) => row.id !== id);
    const updatedAddedRows = addedRows.filter((row) => row.id !== id);
    setServerRows([...updatedServerRows]);
    setAddedRows([...updatedAddedRows]);
    setUpdateTrigger((prev) => prev + 1);
  };

  const handleFormDataChange = (data: Partial<TrainData>) => {
    setFormData(data);
  };
  const handleTableDataChange = (index: number, data: ParseDocument) => {
    const updatedRows = [...addedRows];
    updatedRows[index] = {
      ...data,
      quantity: data.quantity,
      price: data.price,
      tax: data.tax,
      amount: data.amount,
    };
    setAddedRows(updatedRows);
    setUpdateTrigger((prev) => prev + 1);
  };

  const handleValidityChange = (isValid: boolean) => {
    setIsFormValid(isValid);
  };

  const createParsePayload = (): UploadDocumentPayload => {
    const {
      supplierName = '',
      supplierAddress = '',
      supplierEmailAddress = '',
      supplierPhoneNumber = '',
      clientName = '',
      clientAddress = '',
      clientEmailAddress = '',
      clientPhoneNumber = '',
      invoiceNumber = '',
      invoiceCreatedDate = '',
      invoiceDueDate = '',
      totalAmount = 0,
      totalVAT = 0,
    } = formData;

    const filteredLineItems = [...serverRows, ...addedRows]
      .filter((item) => item.description || item.quantity || item.price || item.tax || item.amount)
      .map(({ ...rest }) => rest);

    return {
      supplierName,
      supplierAddress,
      supplierEmailAddress,
      supplierPhoneNumber,
      clientName,
      fileName: fileData.fileName,
      fileBase64String: fileData.fileBase64,
      clientAddress,
      clientEmailAddress,
      clientPhoneNumber,
      invoiceNumber,
      dueDate: invoiceDueDate ? new Date(startOfDay(new Date(invoiceDueDate))).toISOString() : '',
      issuedDate: invoiceCreatedDate ? new Date(startOfDay(new Date(invoiceCreatedDate))).toISOString() : '',
      totalAmount: totalAmount,
      totalTax: totalVAT,
      invoiceLineItems: filteredLineItems,
    };
  };

  const createTrainPayload = (): TrainDocumentTypePayload => {
    const {
      supplierName = '',
      supplierAddress = '',
      supplierEmailAddress = '',
      supplierPhoneNumber = '',
      clientName = '',
      clientAddress = '',
      clientEmailAddress = '',
      clientPhoneNumber = '',
      invoiceNumber = '',
      invoiceCreatedDate = '',
      invoiceDueDate = '',
      totalAmount = 0,
      totalVAT = 0,
    } = formData;

    const filteredLineItems = [...serverRows, ...addedRows]
      .filter((item) => item.description || item.quantity || item.price || item.tax || item.amount)
      .map(({ ...rest }) => rest);

    return {
      documentTypeId,
      documentName: fileData.fileName,
      documentBase64String: fileData.fileBase64,
      trainData: {
        supplierName,
        supplierAddress,
        supplierEmailAddress,
        supplierPhoneNumber,
        clientName,
        clientAddress,
        clientEmailAddress,
        clientPhoneNumber,
        invoiceNumber,
        invoiceCreatedDate: invoiceCreatedDate ? new Date(startOfDay(new Date(invoiceCreatedDate))).toISOString() : '',
        invoiceDueDate: invoiceDueDate ? new Date(startOfDay(new Date(invoiceDueDate))).toISOString() : '',
        totalAmount: totalAmount,
        totalVAT: totalVAT,
        invoiceLineItems: filteredLineItems,
      },
    };
  };

  const handleUploadDocument = async (parsePayload: UploadDocumentPayload) => {
    try {
      await uploadDocument(parsePayload);
      isDocumentTypeSelected();
      successNotify(t('InvoiceSuccessfullyCreated'));
    } catch (error) {
      handleOpenErrorModal();
    }
  };

  const handleTrainDocument = async (trainPayload: TrainDocumentTypePayload) => {
    try {
      await trainDocumentType(trainPayload).unwrap();
      await startTraining(documentTypeId).unwrap();
      navigate(ROUTES.typlessDocumentTypesPage);
      successNotify(t('TrainingStarted'));
    } catch (error) {
      handleOpenErrorModal();
    }
  };

  const handleSubmit = async () => {
    if (uploadDocumentLogic) {
      const parsePayload = createParsePayload();
      await handleUploadDocument(parsePayload);
    } else {
      const trainPayload = createTrainPayload();
      await handleTrainDocument(trainPayload);
    }
  };

  useEffect(() => {
    setAddedRows([...addedRows]);
  }, [updateTrigger]);

  useEffect(() => {
    const parseDocumentAsync = async () => {
      if (!fileData.fileUrl || documentNumberOfTrainings <= 0 || !fileData.fileBase64) {
        return;
      }
      const payload = {
        documentBase64String: fileData.fileBase64,
        documentName: fileData.fileName,
        documentTypeId: Number(data?.result?.id),
      };
      try {
        await parseDocument(payload).unwrap();
        successfullMessage && successNotify(t('InvoiceSuccessfullyParsed'));
      } catch (error) {
        handleOpenErrorModal();
      }
    };

    parseDocumentAsync();
  }, [fileData.fileUrl, fileData.fileBase64]);

  useEffect(() => {
    if (startTrainingData) {
      setFormData({
        ...formData,
        ...startTrainingData,
      });
    }
  }, [startTrainingData]);

  useEffect(() => {
    if (parseData?.result?.lineItems && serverRows.length === 0) {
      setServerRows(parseData.result.lineItems.map((item) => ({ ...item, id: item.id || uuidv4() })));
    }
  }, [parseData, serverRows.length]);

  return (
    <>
      <div className="flex">
        <div className="flex-grow p-2 flex justify-center overflow-hidden">
          {fileData.fileUrl && (
            <DndProvider backend={HTML5Backend}>
              <PDFViewer fileUrl={fileData.fileUrl} className="h-[67rem]" />
            </DndProvider>
          )}
        </div>
        <div className="w-3/12 min-w-[200px] p-2">
          <DocumentDetailsFormInfo
            onUpdate={handleFormDataChange}
            onValidityChange={handleValidityChange}
            parseData={parseData}
          />
        </div>
      </div>
      <Table
        dataCy="train-document-table"
        columns={TRAIN_DOCUMENT_TABLE_COLUMNS}
        isLoading={isLoading}
        showAddButton
        addButtonText={t('AddLineItem')}
        addButtonIcon={faPlus as IconDefinition}
        onAddRow={handleAddRow}
      >
        {serverRows.map(
          (item) =>
            item.id && (
              <TrainDocumentRow key={item.id} ordersData={item} onDeleteField={() => handleDeleteRow(item.id)} />
            )
        )}
        {addedRows.map((row, index) => (
          <TrainDocumentRow
            key={row.id}
            ordersData={row}
            onDeleteField={() => handleDeleteRow(row.id)}
            onUpdate={(data) => handleTableDataChange(index, data)}
          />
        ))}
      </Table>
      <div className="mt-14 flex items-center justify-between">
        <Button isLoading={isLoading} type="button" variant={ButtonVariants.OUTLINE} onClick={handleFileReset}>
          {t('Cancel')}
        </Button>

        <Button
          isLoading={isLoading}
          dataCy="submit-button"
          type="submit"
          onClick={handleSubmit}
          disabled={!isFormValid}
        >
          {t(saveButton)}
        </Button>
      </div>
    </>
  );
};

export default TyplessDocumentTypesDetailsBlock;
