import { ReactNode } from 'react';

import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { Skeleton } from 'shared-components/index';
import { cn } from 'utils/global';

export interface Props {
  label: string;
  value?: string | number | null | ReactNode;
  labelClassName?: string;
  valueClassName?: string;
  iconClassName?: string;
  displayAlways?: boolean;
  children?: ReactNode;
  icon?: IconDefinition;
  bodyClassName?: string;
  withColon?: boolean;
  isLoading?: boolean;
  skeletonClassName?: string;
  dataCy?: string;
}

const LabelWithValue = ({
  label,
  value,
  displayAlways,
  labelClassName,
  valueClassName,
  bodyClassName,
  iconClassName,
  children,
  withColon = true,
  isLoading,
  skeletonClassName,
  dataCy = 'label-with-value',
  icon,
}: Props) => {
  const { t } = useTranslation();

  const sharedStyles = 'text-sm flex flex-col sm:flex-row mb-2 dark:text-gray';
  if (!value && !children && !displayAlways) {
    return null;
  }

  return (
    <>
      <div data-cy={dataCy} className={cn(sharedStyles, bodyClassName)}>
        {icon && <FontAwesomeIcon className={cn('flex', iconClassName)} icon={icon} />}
        <p data-cy="label" className={cn('pr-2 text-bodyMd', labelClassName)}>
          {t(label)}
          {withColon ? ':' : ''}
        </p>
        {isLoading ? (
          <Skeleton className={cn('w-52 h-full', sharedStyles, bodyClassName, skeletonClassName)} />
        ) : (
          <>
            {value && (
              <p data-cy="value" className={cn('text-darkGray dark:text-white flex items-center', valueClassName)}>
                {t(`${value}`)}
              </p>
            )}
            {children}
          </>
        )}
      </div>
    </>
  );
};

export default LabelWithValue;
