import { useTranslation } from 'react-i18next';

import { SubscriptionsWithFeatures } from 'interfaces/subscriptions-tenant.interface';
import { formatDataCy } from 'utils/global';

import SubscriptionButtonModalControls from './SubscriptionButtonModalControls';
import SubscriptionFeature from './SubscriptionFeature';
import SubscriptionPlans from './SubscriptionPlans';

interface Props {
  subscription: SubscriptionsWithFeatures;
  allFeaturesLookup?: Record<string, string>;
  isLoading: boolean;
}

export const SubscriptionCard = ({ subscription, allFeaturesLookup, isLoading }: Props) => {
  const { t } = useTranslation();

  const { displayName, subscriptionPlans } = subscription.subscription;

  return (
    <>
      <div
        data-cy={formatDataCy(displayName)}
        className="flex flex-col h-full border-1 border-neutral-200 dark:border-neutral-800 bg-white-00 dark:bg-black-900 rounded-md mx-5"
      >
        <div className="flex-grow">
          <h4 className="text-md w-full text-start rounded-t-md px-5 py-4 text-neutral-900 dark:text-neutral-100">
            {displayName}
          </h4>
          <SubscriptionPlans
            plans={subscriptionPlans}
            isLoading={isLoading}
            isFree={subscription.subscription.isFree}
          />
          {subscriptionPlans.some((plan) => plan.price) && (
            <div className="flex flex-row text-primary-500 px-5 text-bodyMd mb-4">
              <p>*</p>
              {t('ExcludingVat')}
            </div>
          )}
        </div>
        <div className="border-t border-neutral-200 dark:border-neutral-800 h-0"></div>
        <div className="flex flex-col my-3 mx-4 flex-grow">
          <SubscriptionFeature
            featureValues={subscription.featureValues}
            allFeaturesLookup={allFeaturesLookup}
            isLoading={isLoading}
          />
        </div>

        <SubscriptionButtonModalControls
          subscription={subscription}
          isLoading={isLoading}
          allFeaturesLookup={allFeaturesLookup}
        />
      </div>
    </>
  );
};

export default SubscriptionCard;
