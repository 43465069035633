import { IconDefinition, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { useDeleteOrganizationUnitRoleMutation } from 'apis/organization-units.api';
import { ButtonVariants } from 'constants/common';
import { DAY_FORMAT_WITH_SLASHES } from 'constants/global';
import { useOpen } from 'hooks/useOpen';
import { SelectedUnitInfo, SharedRolesData } from 'interfaces/organization-units.interface';
import { CommonError } from 'interfaces/shared.interface';
import { Button, ErrorModal, TableData, TableRow, WarningModal } from 'shared-components';
import { getFormattedDate } from 'utils/dates';
import { successNotify } from 'utils/notifications';

type Props = {
  userData: SharedRolesData;
  selectedUnit: SelectedUnitInfo | null;
  refetchRoles: VoidFunction;
};

const RolesTabRow = ({ userData, selectedUnit, refetchRoles }: Props) => {
  const { t } = useTranslation();

  const [isOpenInfoModal, handleOpenInfoModal, handleCloseInfoModal] = useOpen();
  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();

  const [
    deleteOrganizationRoleUnit,
    { isLoading: isDeleteOrganizationRoleLoading, error: deleteOrganizationRoleError },
  ] = useDeleteOrganizationUnitRoleMutation();

  const errorData = (deleteOrganizationRoleError as CommonError)?.data || {};

  const { id, displayName, addedTime } = userData;

  const handleDeleteUnitUser = async () => {
    const roleId = id;
    const unitId = selectedUnit?.id;
    if (typeof unitId === 'number') {
      try {
        await deleteOrganizationRoleUnit({ roleId, unitId }).unwrap();
        refetchRoles();
        handleCloseInfoModal();
        successNotify(t('SuccessfullyDeleted'));
      } catch (error) {
        handleOpenErrorModal();
      }
    }
  };

  return (
    <>
      <ErrorModal
        errorMessage={errorData?.error?.message}
        description={errorData?.error?.details}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />
      <WarningModal
        warningMessage={t('AreYouSure')}
        description={t('RemoveRoleFromOuWarningMessage', {
          0: displayName,
          1: selectedUnit?.displayName,
        })}
        isOpen={isOpenInfoModal}
        handleClose={handleCloseInfoModal}
        handleConfirm={handleDeleteUnitUser}
      />

      <TableRow dataCy="roles-table-row">
        <TableData>
          <Button
            isLoading={isDeleteOrganizationRoleLoading}
            type="button"
            dataCy="delete-role-button"
            variant={ButtonVariants.DESTRUCTIVE}
            onClick={handleOpenInfoModal}
          >
            <FontAwesomeIcon icon={faXmark as IconDefinition} />
          </Button>
        </TableData>
        <TableData>{displayName}</TableData>
        <TableData>{getFormattedDate(addedTime, DAY_FORMAT_WITH_SLASHES)}</TableData>
      </TableRow>
    </>
  );
};

export default RolesTabRow;
