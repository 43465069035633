import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { ButtonVariants } from 'constants/common';
import { useBeforeRouteChange } from 'hooks/useBeforeRouteChange';
import { useOpen } from 'hooks/useOpen';
import { setIsEditMode } from 'modules/dashboard/action';
import { selectIsEditMode } from 'modules/dashboard/selector';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { Button, ToggleSwitch } from 'shared-components';

import { DashboardFiltersModal } from './DashboardFiltersModal';

export const DashboardFilters = () => {
  const dispatch = useAppDispatch();
  const isEditMode = useAppSelector(selectIsEditMode);

  const [isOpenFiltersModal, handleOnOpenFiltersModal, handleOnCloseFiltersModal] = useOpen();
  const { t } = useTranslation();

  const handleEditModeToggle = () => {
    dispatch(setIsEditMode(!isEditMode));
  };

  useBeforeRouteChange(() => dispatch(setIsEditMode(false)));

  return (
    <div className="flex gap-2" data-cy="dashboard-filters">
      <Button variant={ButtonVariants.PLAIN} onClick={handleOnOpenFiltersModal} data-cy="open-filters-modal">
        <FontAwesomeIcon className="w-4 h-4 fill-black dark:fill-white" icon={faFilter} />
      </Button>
      <div className="flex items-center gap-2 text-sm">
        <ToggleSwitch dataCy="edit-mode-switch" isEnabled={isEditMode} onToggle={handleEditModeToggle} />
        <span>{t('EditMode')}</span>
      </div>
      <DashboardFiltersModal isOpen={isOpenFiltersModal} handleClose={handleOnCloseFiltersModal} />
    </div>
  );
};
