import { FC, ReactNode } from 'react';

import { useTranslation } from 'react-i18next';

import FooterForm from './FooterForm';

export interface Props {
  children: ReactNode;
  title: string;
  containerClassName?: string;
}

const AuthFormContainer: FC<Props> = ({ title, children, containerClassName }) => {
  const { t } = useTranslation();
  return (
    <div className="flex h-screen bg-neutral-100">
      <div className="w-full lg:w-5/12 flex items-center justify-center px-10 rounded-[0.625rem] border border-neutral-300  bg-white-00">
        <div className={`w-[26.5rem] ${containerClassName}`}>
          <h1 className="text-4xl text-neutral-800 mb-8">{t(title)}</h1>
          {children}
          <FooterForm />
        </div>
      </div>

      <div className="hidden lg:block w-7/12"></div>
    </div>
  );
};

export default AuthFormContainer;
