import { FC, useEffect } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ButtonVariants } from 'constants/common';
import useUserRole from 'hooks/useUserRole';
import { DashboardFiltersForm } from 'interfaces/dashboard.interface';
import { setDateRange } from 'modules/dashboard/action';
import { useAppDispatch } from 'modules/store';
import { Button, Modal, RangeDatePickerInput } from 'shared-components';

export interface Props {
  errorMessage?: string;
  isOpen: boolean;
  handleClose: VoidFunction;
  description?: string;
}

export const DashboardFiltersModal: FC<Props> = ({ isOpen, handleClose }) => {
  const { t } = useTranslation();
  const { control, handleSubmit, watch, reset } = useForm<DashboardFiltersForm>();

  const dispatch = useAppDispatch();

  const { isAdmin } = useUserRole();

  const watchDate = watch('dateRange');

  const onClose = () => {
    reset();
    handleClose();
  };

  const onSubmit: SubmitHandler<DashboardFiltersForm> = ({ dateRange }) => {
    const [startDate, endDate] = dateRange;

    if (isAdmin) {
      dispatch(setDateRange({ startDate: startDate.toISOString(), endDate: endDate.toISOString() }));
    }
  };

  useEffect(() => {
    if (watchDate) {
      handleSubmit(onSubmit)();
    }
  }, [handleSubmit, watchDate]);

  return (
    <Modal
      bodyClassName="md:w-2/5 2xl:w-1/4"
      heading="DashboardFilters"
      dataCy="dashboard-filter-modal"
      isOpen={isOpen}
      onClose={onClose}
      hideCross
    >
      <div className="flex flex-col gap-8">
        <form onSubmit={handleSubmit(onSubmit)}>
          <RangeDatePickerInput
            control={control}
            name="dateRange"
            label="FilterDateRangePicker"
            placeholder={['StartDate', 'EndDate']}
            dataCy="filter-date-range"
          />
        </form>
        <Button className="self-end" onClick={onClose} dataCy="modal-close-button" variant={ButtonVariants.OUTLINE}>
          {t('Close')}
        </Button>
      </div>
    </Modal>
  );
};
