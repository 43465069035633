import { ReactNode, useRef } from 'react';

import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';

import { BackButton, Skeleton } from 'shared-components';
import { cn } from 'utils/global';

export interface Props {
  children: ReactNode;
  onClose?: VoidFunction;
  isLoading?: boolean;
  isPaymentInfo?: boolean;
  heading: string;
  headingClassName?: string;
  endChild?: ReactNode;
  dataCy?: string;
  isBackButton?: boolean;
  bodyClassName?: string;
  contentClassName?: string;
  wrapperClassName?: string;
}

const TenantSubscriptionModal = ({
  children,
  onClose,
  isLoading,
  isPaymentInfo,
  heading,
  endChild,
  dataCy,
  isBackButton,
  headingClassName,
  bodyClassName,
  contentClassName,
  wrapperClassName,
}: Props) => {
  const modalRef = useRef(null);
  const portalElement = document.getElementById('modal-root');
  const { t } = useTranslation();

  if (!portalElement) {
    return null;
  }

  return createPortal(
    <>
      <div
        data-cy={dataCy}
        className={
          'justify-center dark:!bg-neutral-900 !bg-neutral-100 flex overflow-x-hidden overflow-y-auto items-baseline sm:items-center fixed inset-0 z-40 sm:p-3 p-0 bg-transparent'
        }
      >
        <div
          className={cn('relative my-0 min-w-min self-center shadow-lg', bodyClassName, {
            'sm:my-6 sm:w-11/12 md:w-4/5 w-full sm:min-h-fit min-h-full self-auto min-w-0 shadow-none': !isPaymentInfo,
          })}
        >
          <div
            className={cn(
              'shadow-none border-b-0 rounded-md relative flex flex-col dark:bg-black-900 bg-white-00 outline-none focus:outline-none px-0 sm:px-1 h-full p-3',
              { 'shadow-lg border-0': !isPaymentInfo }
            )}
            ref={modalRef}
          >
            <div className={cn('block justify-between pb-4 sm:rounded-md rounded-none items-center', wrapperClassName)}>
              {isBackButton && (
                <>
                  <div>
                    <BackButton onClick={onClose} />
                  </div>
                </>
              )}
            </div>
            <div
              data-cy="content"
              className={cn(
                'my-4 block dark:text-neutral-300 text-neutral-700 relative px-[0.625rem]',
                contentClassName
              )}
            >
              {isLoading ? <Skeleton className="w-full h-64" /> : children}
            </div>
          </div>

          {endChild}
        </div>
      </div>
    </>,
    portalElement
  );
};

export default TenantSubscriptionModal;
