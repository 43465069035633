import { useTranslation } from 'react-i18next';

import { ButtonVariants } from 'constants/common';
import { NameValueResult } from 'interfaces/shared.interface';
import { Button, Modal } from 'shared-components';

import SubscriptionFeature from './SubscriptionFeature';

interface Props {
  isFree: boolean;
  displayName: string;
  handleCloseModal: VoidFunction;
  isModalOpen: boolean;
  featureValues: NameValueResult[];
  allFeaturesLookup?: Record<string, string>;
  isLoading: boolean;
  handleButtonClick: VoidFunction;
  isWarningModalOpen: boolean;
  isTrailSelectable: number | boolean | null;
  handleTrialButtonClick: VoidFunction;
  isLoadingEvent: boolean;
}

export const SubscriptionDetailModal = ({
  isFree,
  displayName,
  handleCloseModal,
  featureValues,
  isModalOpen,
  allFeaturesLookup,
  isLoading,
  handleButtonClick,
  isWarningModalOpen,
  isTrailSelectable,
  handleTrialButtonClick,
  isLoadingEvent,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Modal
      heading={displayName}
      isOpen={isModalOpen}
      onClose={handleCloseModal}
      bodyClassName="!w-[21.25rem] flex justify-center items-center"
    >
      {isFree && <div className="mb-8">{t('Free')}</div>}

      <SubscriptionFeature
        featureValues={featureValues}
        allFeaturesLookup={allFeaturesLookup}
        isLoading={isLoading}
        headingClassName="!mb-6"
        bodyClassName="flex flex-col"
      />
      <div className="mt-8">
        <Button className="w-full" onClick={handleButtonClick}>
          {t('Select')}
        </Button>
        {isTrailSelectable && (
          <Button
            dataCy="try-it-button"
            className="w-full mt-4"
            isLoading={isLoadingEvent}
            onClick={handleTrialButtonClick}
            variant={ButtonVariants.OUTLINE}
          >
            {t('TryIt')}
          </Button>
        )}
      </div>
      {isWarningModalOpen && <div className="opacity-25 fixed inset-0 z-40 bg-black" />}
    </Modal>
  );
};

export default SubscriptionDetailModal;
