import { FC } from 'react';

import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import Button from './Button';

export interface Props {
  onClick?: VoidFunction;
}
const BackButton: FC<Props> = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <Button icon={faArrowLeft} className="text-start text-primary-500 !bg-transparent mb-4" onClick={onClick}>
      {t('Back')}
    </Button>
  );
};

export default BackButton;
