import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { useLazyGetUserProfilePictureQuery } from 'apis/user.api';
import { ReactComponent as ArrowLeft } from 'assets/svg/arrow-left-3.svg';
import { ButtonVariants } from 'constants/common';
import { ROUTES } from 'constants/routes';
import { SupportTicketChatDetails } from 'interfaces/support-tickets.interfaces';
import { Button, LinkButton, Skeleton } from 'shared-components';
import Avatar from 'shared-components/Avatar';
import { joinMimeTypeAndDataToBase64 } from 'utils/files';
import { cn } from 'utils/global';

interface Props {
  headerData?: SupportTicketChatDetails;
  isAdmin?: boolean;
  isLoading: boolean;
}

const ChatHeaderInfo = ({ headerData, isAdmin, isLoading }: Props) => {
  const [isTruncated, setIsTruncated] = useState(false);
  const [showMore, setShowMore] = useState(true);

  const { t } = useTranslation();

  const {
    chatCompanionFullName,
    chatCompanionTenantName,
    chatCompanionTenantId,
    subject,
    description,
    chatCompanionProfileImageId,
  } = headerData || {};

  const [fetchProfilePicture, { data: pictureResult, isLoading: isProfilePictureLoading }] =
    useLazyGetUserProfilePictureQuery();

  const { profilePicture, mimeType } = pictureResult?.result || {};

  const profileAvatar = joinMimeTypeAndDataToBase64({ data: profilePicture, mimeType });

  const CHAR_LIMIT = 24;

  useEffect(() => {
    if (description && description.length > CHAR_LIMIT) {
      setIsTruncated(true);
    } else {
      setIsTruncated(false);
    }
  }, [description]);

  useEffect(() => {
    if (chatCompanionProfileImageId) {
      fetchProfilePicture({ profilePictureId: chatCompanionProfileImageId, tenantId: chatCompanionTenantId });
    }
  }, [chatCompanionProfileImageId, chatCompanionTenantId, fetchProfilePicture]);

  return (
    <div
      data-cy="chat-header"
      className="bg-white p-16 py-4 px-6 border-b border-gray3 rounded-t-md dark:bg-darkBlue dark:text-gray dark:border-darkGray3"
    >
      <div className="mb-2">
        <LinkButton
          className="w-24"
          to={isAdmin ? ROUTES.supportTickets : ROUTES.supportRequests}
          variant={ButtonVariants.OUTLINE}
        >
          <ArrowLeft className="fill-neutral-700 mr-1.5" />
          {t('Back')}
        </LinkButton>
      </div>
      <div className="flex max-md:flex-col-reverse">
        <div className={cn('w-full md:w-3/5', { 'md:w-3/5': showMore })}>
          <div className="text-sm flex" data-cy="chat-header-subject">
            {t('Subject')}:&nbsp;
            {isLoading ? (
              <Skeleton className="w-40 h-5" />
            ) : (
              <div className="text-darkGray dark:text-white">{subject}</div>
            )}
          </div>
          <div className={cn('flex items-center w-full', { block: showMore, flex: !isTruncated })}>
            <p className="place-self-start text-sm">{t('Description')}:&nbsp;</p>
            <div className={cn('text-sm overflow-hidden', { 'pl-4 flex': showMore, 'pl-0': !isTruncated })}>
              {showMore ? (
                <span>
                  {description}&nbsp;
                  {isTruncated && (
                    <Button className="h-auto" variant={ButtonVariants.OUTLINE} onClick={() => setShowMore(false)}>
                      {t('ViewLess')}
                    </Button>
                  )}
                </span>
              ) : (
                <span className="flex">
                  <p className="overflow-hidden whitespace-nowrap text-ellipsis lg:max-w-[16rem] xl:max-w-[32rem] 2xl:max-w-[48rem]">{`${description}`}</p>
                  {isTruncated && (
                    <Button
                      className="ml-0 sm:ml-2 h-auto block sm:contents"
                      variant={ButtonVariants.OUTLINE}
                      onClick={() => setShowMore(true)}
                    >
                      {t('ViewMore')}
                    </Button>
                  )}
                </span>
              )}
            </div>
          </div>
        </div>
        {chatCompanionFullName && (
          <div className="w-full flex md:w-2/5 md:justify-end ">
            <div className="block flex-col items-end mr-4 max-md:order-2 max-md:items-start">
              <div className="flex text-sm mb-2" data-cy="chat-header-name">
                {t(`${isAdmin ? 'Requester' : 'Responder'}`)}:&nbsp;
                {isLoading ? (
                  <Skeleton className="w-60 h-5" />
                ) : (
                  <div className="text-darkGray dark:text-white">{chatCompanionFullName}</div>
                )}
              </div>
              {isAdmin && (
                <div className="flex text-sm mb-2">
                  {t('TenantName')}:&nbsp;
                  <div className="text-darkGray dark:text-white" data-cy="chat-header-tenant-name">
                    {isLoading ? <Skeleton className="w-40 h-5" /> : <div>{chatCompanionTenantName}</div>}
                  </div>
                </div>
              )}
            </div>
            <Avatar
              imagePath={profileAvatar}
              isLoading={isProfilePictureLoading}
              className="w-10 h-10 m-1 rounded-full border border-lightGray6 shadow"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatHeaderInfo;
