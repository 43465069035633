import { useNavigate } from 'react-router-dom';

import { useAuthenticateMutation } from 'apis/auth.api';
import { user } from 'apis/user.api';
import { ROUTES } from 'constants/routes';
import { SignInPayload } from 'interfaces/auth.interface';
import { getAuthCredentials } from 'modules/auth/action';
import { useAppDispatch } from 'modules/store';
import { setAccessTokenToCookie } from 'utils/auth';

import { useOpen } from './useOpen';

export const useHandleSignIn = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [authenticate, { isLoading, error }] = useAuthenticateMutation();
  const [isOpenErrorModal, handleOnOpenErrorModal, handleOnCloseErrorModal] = useOpen();

  const handleSignIn = async (payloadData: SignInPayload) => {
    dispatch(getAuthCredentials(payloadData));

    try {
      const response = await authenticate(payloadData).unwrap();
      if (!response.result) {
        return;
      }

      const { accessToken, encryptedAccessToken, storybookAuthToken, expireInSeconds, twoFactorAuthenticationType } =
        response.result;

      if (response.result.resetPasswordOnLogin && response.result.passwordResetUrl) {
        const url = new URL(response.result.passwordResetUrl);
        const cParamValue = url.searchParams.get('c');

        const encodedCParamValue = encodeURIComponent(cParamValue || '');
        const resetPasswordUrl = `${ROUTES.resetPassword}?c=${encodedCParamValue}`;

        navigate(resetPasswordUrl);
        return;
      }

      if (
        !response.result.resetPasswordOnLogin &&
        twoFactorAuthenticationType &&
        !payloadData.twoFactorAuthenticationCode
      ) {
        navigate(ROUTES.twoFactorAuthenticator);
        return;
      }

      setAccessTokenToCookie(accessToken, encryptedAccessToken, storybookAuthToken, expireInSeconds);

      if (user.util) {
        dispatch(user.util.resetApiState());
      }

      navigate(ROUTES.dashboard);
    } catch (error) {
      handleOnOpenErrorModal();
    }
  };

  return { handleSignIn, isLoading, error, isOpenErrorModal, handleOnCloseErrorModal };
};
