import { FC, ReactNode } from 'react';

import { Link, LinkProps } from 'react-router-dom';

import { ButtonSizeVariants, ButtonSizeVariantsStyles, ButtonVariants, ButtonVariantStyles } from 'constants/common';
import { cn } from 'utils/global';

interface Props extends LinkProps {
  children: ReactNode;
  className?: string;
  variant?: ButtonVariants;
  to: string;
  size?: ButtonSizeVariants;
}

const LinkButton: FC<Props> = ({
  children,
  variant = ButtonVariants.PRIMARY,
  className,
  to,
  size = ButtonSizeVariants.LG,
  ...props
}) => {
  return (
    <Link
      to={to}
      className={cn('flex items-center', ButtonVariantStyles[variant], ButtonSizeVariantsStyles[size], className)}
      {...props}
    >
      {children}
    </Link>
  );
};

export default LinkButton;
