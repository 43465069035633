import { BannerProps } from 'interfaces/subscriptions-tenant.interface';

import SubscriptionDeactivatedBanner from './SubscriptionDeactivatedBanner';
import SubscriptionExpiredBanner from './SubscriptionExpiredBanner';

const SubscriptionBannerHandler = ({
  isSubscriptionManagementPermission,
  isSubscriptionDeactivated,
  expiringSubscriptionName,
  loading,
  daysAmount,
  subscriptionIsExpiringSoon,
  subscriptionAwaitingCancellation,
  displayName,
}: BannerProps) => {
  const buildMessage = () => {
    if (subscriptionIsExpiringSoon && !isSubscriptionDeactivated && subscriptionAwaitingCancellation) {
      return (
        <SubscriptionExpiredBanner
          loading={loading}
          daysAmount={daysAmount}
          isSubscriptionManagementPermission={isSubscriptionManagementPermission}
          expiringSubscriptionName={expiringSubscriptionName}
        />
      );
    }

    if (isSubscriptionDeactivated) {
      return (
        <SubscriptionDeactivatedBanner
          loading={loading}
          daysAmount={daysAmount}
          isSubscriptionManagementPermission={isSubscriptionManagementPermission}
          expiringSubscriptionName={expiringSubscriptionName}
          displayName={displayName}
        />
      );
    }

    return null;
  };

  const message = buildMessage();

  return !loading && message ? (
    <div data-cy="banner" className="w-full block text-center py-2 bg-primary-500 text-white-00 font-bold">
      {message}
    </div>
  ) : null;
};

export default SubscriptionBannerHandler;
