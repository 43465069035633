import { useTranslation } from 'react-i18next';

import { UserDetails } from 'page-components/user-management';

const user = {
  name: 'John Doe',
  email: 'john@mail.com',
  status: 'active',
  role: 'Editor',
  verified: true,
  registered: '01.01.2021',
};

const ManagementPage = () => {
  const { t } = useTranslation();

  return (
    <div className="h-full">
      <h1 className="text-4xl font-bold my-10 text-center">{t('UserManagement')}</h1>
      <UserDetails user={user} />
    </div>
  );
};

export default ManagementPage;
