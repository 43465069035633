import { useTranslation } from 'react-i18next';

import { SIGN_IN_PAGE_TITLE } from 'constants/sign-in';
import { SignInForm } from 'page-components/sign-in';
import AuthFormContainer from 'shared-components/AuthFormContainer';

const SignIn = () => {
  const { t } = useTranslation();

  return (
    <AuthFormContainer title={t(SIGN_IN_PAGE_TITLE)}>
      <SignInForm />
    </AuthFormContainer>
  );
};

export default SignIn;
