import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ButtonVariants } from 'constants/common';
import { ROUTES } from 'constants/routes';
import {
  SIGN_IN_FORM_INPUT,
  SIGN_IN_FORM_INPUTS,
  SIGN_IN_PAGE_ACTIVATE_EMAIL_TEXT,
  SIGN_IN_PAGE_FORGOT_PASSWORD_TEXT,
} from 'constants/sign-in';
import { useHandleSignIn } from 'hooks/useHandleSignIn';
import { SignInPayload } from 'interfaces/auth.interface';
import { CommonError } from 'interfaces/shared.interface';
import { SIGN_IN_FORM_FIELDS, SignInFormValues } from 'interfaces/sign-in.interface';
import { Button, ErrorModal, FormContent, LinkButton } from 'shared-components';

const SignInForm = () => {
  const { handleSignIn, isLoading, error, isOpenErrorModal, handleOnCloseErrorModal } = useHandleSignIn();
  const { data: errorData } = (error as CommonError) || {};

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignInFormValues>({ mode: 'onBlur' });

  const onSubmit = async (data: SignInFormValues) => {
    const payloadData = {
      password: data[SIGN_IN_FORM_FIELDS.password],
      userNameOrEmailAddress: data[SIGN_IN_FORM_FIELDS.userName],
      rememberClient: data[SIGN_IN_FORM_FIELDS.rememberMe],
    } as SignInPayload;

    try {
      await handleSignIn(payloadData);
    } catch (error) {
      console.error(error);
    }
  };
  const { t } = useTranslation();

  return (
    <>
      <ErrorModal
        errorMessage={errorData?.error?.message}
        description={errorData?.error?.details}
        isOpen={isOpenErrorModal}
        handleClose={handleOnCloseErrorModal}
      />

      <form data-cy="sign-in-form" onSubmit={handleSubmit(onSubmit)} className="mt-9">
        <FormContent fields={SIGN_IN_FORM_INPUT} register={register} errors={errors} />
        <div data-cy="sign-in-extra-link" className=" flex justify-end">
          <Link to={ROUTES.activateEmail} className="text-blue-500 text-bodyMd-underline hover:text-darkBlue5">
            {t(SIGN_IN_PAGE_ACTIVATE_EMAIL_TEXT)}
          </Link>
        </div>
        <FormContent fields={SIGN_IN_FORM_INPUTS} register={register} errors={errors} />
        <div className="flex justify-between">
          <div data-cy="sign-in-extra-link" className="mt-1">
            <Link to={ROUTES.forgotPassword} className="text-blue-500 text-bodyMd-underline hover:text-darkBlue5">
              {t(SIGN_IN_PAGE_FORGOT_PASSWORD_TEXT)}
            </Link>
          </div>
        </div>
        <div className="flex flex-col items-center">
          <Button
            data-cy="submit-button"
            isLoading={isLoading}
            className="text-sm w-full mt-6"
            variant={ButtonVariants.PRIMARY}
            type="submit"
          >
            {t('LogIn')}
          </Button>
          <p className="text-xs my-4">{t('NotAUser')}</p>
          <LinkButton
            to={ROUTES.signUp}
            className="w-[17.175rem] flex justify-center"
            variant={ButtonVariants.OUTLINE}
            data-cy="sign-in-extra-link"
            type="button"
          >
            {t('Register')}
          </LinkButton>
        </div>
      </form>
    </>
  );
};

export default SignInForm;
