import { useEffect, useState } from 'react';

import { differenceInDays, differenceInMilliseconds, parseISO } from 'date-fns';

import { BannerProps, Permissions } from 'interfaces/user.interface';
import { useAppSelector } from 'modules/store';
import { selectUserPermissions } from 'modules/user/selector';
import SubscriptionBannerHandler from 'page-components/subscriptions-tenant/banner/SubscriptionBannerHandler';
import TrialBanner from 'page-components/subscriptions-tenant/banner/TrialBanner';

const Banner: React.FC<BannerProps> = ({
  isInTrialPeriod,
  displayName,
  tenantId,
  subscriptionId,
  subscriptionEndDateUtc,
  subscriptionAwaitingCancellation,
  isSubscriptionDeactivated,
  expiringSubscriptionName,
}) => {
  const permissions = useAppSelector(selectUserPermissions);

  const [loading, setLoading] = useState(true);

  const [daysAmount, setDaysAmount] = useState(0);

  useEffect(() => {
    if (subscriptionEndDateUtc) {
      const endDate = parseISO(subscriptionEndDateUtc);
      const currentDate = new Date();
      const diffInMilliseconds = differenceInMilliseconds(endDate, currentDate);
      const fullDays = differenceInDays(endDate, currentDate);
      const hasPartialDay = diffInMilliseconds > 0 && diffInMilliseconds % (1000 * 60 * 60 * 24) > 0;
      setDaysAmount(hasPartialDay ? fullDays + 1 : fullDays);
    }
    setLoading(false);
  }, [subscriptionEndDateUtc]);

  const subscriptionIsExpiringSoon = daysAmount < 10;

  const isSubscriptionManagementPermission = !!permissions[Permissions.PAGES_TENANT_SUBSCRIPTION_MANAGEMENT];

  return (
    <div>
      {isInTrialPeriod && !isSubscriptionDeactivated ? (
        <TrialBanner
          isSubscriptionManagementPermission={isSubscriptionManagementPermission}
          subscriptionAwaitingCancellation={subscriptionAwaitingCancellation}
          isSubscriptionDeactivated={isSubscriptionDeactivated}
          displayName={displayName}
          tenantId={tenantId}
          subscriptionId={subscriptionId}
          expiringSubscriptionName={expiringSubscriptionName}
          loading={loading}
          daysAmount={daysAmount}
          subscriptionIsExpiringSoon={subscriptionIsExpiringSoon}
        />
      ) : (
        <SubscriptionBannerHandler
          isSubscriptionManagementPermission={isSubscriptionManagementPermission}
          loading={loading}
          isSubscriptionDeactivated={isSubscriptionDeactivated}
          displayName={displayName}
          expiringSubscriptionName={expiringSubscriptionName}
          daysAmount={daysAmount}
          subscriptionIsExpiringSoon={subscriptionIsExpiringSoon}
          subscriptionAwaitingCancellation={subscriptionAwaitingCancellation}
        />
      )}
    </div>
  );
};

export default Banner;
