import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  useLazyGetInfoUpgradeSubscriptionQuery,
  useGetUsedSubscriptionTrialIdsQuery,
  useHasAnyPaymentMutation,
  useLazyGetSubscriptionQuery,
  useSelectedTrialSubscriptionMutation,
  useSelectFreeSubscriptionMutation,
  useLazyGetLastCompletedQuery,
  useUpgradedSubscriptionCostsLessThenMinAmountMutation,
} from 'apis/subscriptions-tenant.api';
import { ButtonVariants } from 'constants/common';
import { ROUTES } from 'constants/routes';
import { useOpen } from 'hooks/useOpen';
import useSubscriptionInformation from 'hooks/useSubscriptionInformation';
import { CommonError } from 'interfaces/shared.interface';
import {
  SubscriptionsWithFeatures,
  SharedSubscriptionIdPayload,
  SubscriptionPlansResult,
} from 'interfaces/subscriptions-tenant.interface';
import { Button, ErrorModal, WarningModal } from 'shared-components';
import { cn } from 'utils/global';
import { getSubscriptionPurchase, getSubscriptionUpgrade } from 'utils/url';

import SubscriptionDetailModal from './SubscriptionDetailModal';

interface Props {
  subscription: SubscriptionsWithFeatures;
  allFeaturesLookup?: Record<string, string>;
  isLoading: boolean;
}

export const SubscriptionButtonModalControls = ({ subscription, allFeaturesLookup, isLoading }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isWarningModalOpen, handleOpenWarningModal, handleCloseWarningModal] = useOpen();
  const [isModalOpen, handleOpenModal, handleCloseModal] = useOpen();
  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();

  const { subscriptionPrice, periodId, tenantId, isInTrialPeriod, refetch } = useSubscriptionInformation();

  const { displayName, subscriptionPlans, isFree, id, trialDayCount } = subscription.subscription;

  const {
    data: useSubscriptionTrialsData,
    isLoading: isUsedSubscriptionTrialLoading,
    error: getSubscriptionQueryError,
  } = useGetUsedSubscriptionTrialIdsQuery(`${tenantId}`);

  const [getInfoUpgradeSubscription, { error: infoUpgradeSubscriptionError }] =
    useLazyGetInfoUpgradeSubscriptionQuery();

  const [selectedTrialMutation, { isLoading: isSelectedTrialLoading, error: selectedTrialError }] =
    useSelectedTrialSubscriptionMutation();

  const [selectFreeSubscription, { isLoading: isSelectedFreeLoading, error: selectedFreeError }] =
    useSelectFreeSubscriptionMutation();

  const [
    upgradedSubscriptionCostsLessThenMinAmount,
    {
      isLoading: isUpgradedSubscriptionCostsLessThenMinAmountLoading,
      error: upgradedSubscriptionCostsLessThenMinAmountError,
    },
  ] = useUpgradedSubscriptionCostsLessThenMinAmountMutation();

  const [hasAnyPayment, { isLoading: isHasAnyPaymentLoading, error: hasAnyPaymentError }] = useHasAnyPaymentMutation();

  const [lastCompleted, { isLoading: isLastCompletedLoading, error: lastCompletedError }] =
    useLazyGetLastCompletedQuery();

  const [getSubscriptionQuery, { error: getSubscriptionError }] = useLazyGetSubscriptionQuery();

  const subscriptionErrorData = (getSubscriptionError as CommonError)?.data || {};
  const selectedTrialErrorData = (selectedTrialError as CommonError)?.data || {};
  const selectedFreeErrorData = (selectedFreeError as CommonError)?.data || {};
  const hasAnyPaymentErrorData = (hasAnyPaymentError as CommonError)?.data || {};
  const lastCompletedErrorData = (lastCompletedError as CommonError)?.data || {};
  const infoUpgradeSubscriptionErrorData = (infoUpgradeSubscriptionError as CommonError)?.data || {};

  const upgradedSubscriptionCostsLessThenMinAmountErrorData =
    (upgradedSubscriptionCostsLessThenMinAmountError as CommonError)?.data || {};

  const getSubscriptionQueryErrorData = (getSubscriptionQueryError as CommonError)?.data || {};

  const errorArray = [
    hasAnyPaymentErrorData,
    lastCompletedErrorData,
    selectedFreeErrorData,
    subscriptionErrorData,
    selectedTrialErrorData,
    getSubscriptionQueryErrorData,
    upgradedSubscriptionCostsLessThenMinAmountErrorData,
    infoUpgradeSubscriptionErrorData,
  ];

  const errorData = errorArray.find((error) => error.error);

  const isTrailSelectable = trialDayCount && !useSubscriptionTrialsData?.result?.includes(id);
  const isDowngradeFromPaidToFree = isFree && subscriptionPrice;

  const isLoadingArray = [
    isLoading,
    isSelectedFreeLoading,
    isHasAnyPaymentLoading,
    isUsedSubscriptionTrialLoading,
    isSelectedTrialLoading,
    isLastCompletedLoading,
    isUpgradedSubscriptionCostsLessThenMinAmountLoading,
  ];

  const isLoadingEvent = isLoadingArray.some((loading) => loading);

  const handleTrialButtonClick = async () => {
    try {
      await getSubscriptionQuery(`${id}`).unwrap();
      await selectedTrialMutation(`${id}`).unwrap();
      refetch?.();
      navigate(ROUTES.dashboard);
      return;
    } catch (error) {
      handleOpenErrorModal();
    }
  };

  const handleFreeSubscriptionSelection = async () => {
    try {
      const payload: SharedSubscriptionIdPayload = {
        subscriptionId: id,
      };

      await getSubscriptionQuery(`${id}`).unwrap();
      await selectFreeSubscription(`${payload.subscriptionId}`).unwrap();
      refetch?.();
      navigate(ROUTES.dashboard);
    } catch (error) {
      handleOpenErrorModal();
    }
  };

  const handleSamePlanPriceClick = async () => {
    try {
      await getSubscriptionQuery(`${id}`).unwrap();
      await hasAnyPayment().unwrap();
      await getInfoUpgradeSubscription(`${id}`).unwrap();
      await lastCompleted().unwrap();
      await upgradedSubscriptionCostsLessThenMinAmount(`${id}`).unwrap();
      refetch?.();
      navigate(ROUTES.dashboard);
      return;
    } catch (error) {
      handleOpenErrorModal();
    }
  };

  const handleButtonClick = async () => {
    const currentPrice = subscriptionPrice ?? 0;

    const findMatchingPlan = (priceCheck: (plan: SubscriptionPlansResult) => boolean) =>
      subscriptionPlans.find((plan) => priceCheck(plan) && plan.periodId === periodId);

    const isCheaperThanCurrentSubscription = findMatchingPlan((plan) => plan.price < currentPrice);
    const isMatchingPlan = findMatchingPlan((plan) => plan.price === currentPrice);
    const planMoreExpensive = findMatchingPlan((plan) => plan.price > currentPrice);

    if (isCheaperThanCurrentSubscription || isDowngradeFromPaidToFree || isInTrialPeriod) {
      handleOpenWarningModal();
    }

    if (currentPrice === 0 && !isInTrialPeriod) {
      navigate(getSubscriptionPurchase(tenantId ?? 0, id, 3));
    }

    if (isMatchingPlan) {
      handleSamePlanPriceClick();
    }

    if (planMoreExpensive) {
      navigate(getSubscriptionUpgrade(id ?? 0));
    }
  };

  return (
    <>
      <ErrorModal
        errorMessage={errorData?.error?.message}
        description={errorData?.error?.details}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />
      <WarningModal
        isOpen={isWarningModalOpen}
        description={t('SubscriptionDowngradeWarningMessage')}
        warningMessage={t('AreYouSure')}
        handleClose={handleCloseWarningModal}
        handleConfirm={isFree ? handleFreeSubscriptionSelection : handleSamePlanPriceClick}
        isCheckConfirmButton
        checkboxLabel={t('SubscriptionDowngradeConfirmationMessage', { 0: displayName })}
      />
      <SubscriptionDetailModal
        allFeaturesLookup={allFeaturesLookup}
        isFree={isFree}
        displayName={displayName}
        handleCloseModal={handleCloseModal}
        isModalOpen={isModalOpen}
        featureValues={subscription.featureValues}
        isLoading={isLoading}
        handleButtonClick={handleButtonClick}
        isWarningModalOpen={isWarningModalOpen}
        isTrailSelectable={isTrailSelectable}
        handleTrialButtonClick={handleTrialButtonClick}
        isLoadingEvent={isLoadingEvent}
      />
      <div className="block mb-4 px-5">
        <p
          data-cy="view-details-text"
          className="text-blue5 text-xs text-bodyMd-underline cursor-pointer text-start"
          onClick={handleOpenModal}
        >
          {t('ViewDetails')}
        </p>
        <div data-cy="bottom-buttons" className="flex flex-col">
          <Button dataCy="select-button" isLoading={isLoadingEvent} className="my-3 w-full" onClick={handleButtonClick}>
            {t('Select')}
          </Button>
          {isTrailSelectable && (
            <Button
              dataCy="try-it-button"
              isLoading={isLoadingEvent}
              className="px-4"
              onClick={handleTrialButtonClick}
              variant={ButtonVariants.OUTLINE}
            >
              {t('TryIt')}
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default SubscriptionButtonModalControls;
