import { FC, useEffect, useMemo, useState, MouseEvent, useRef } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { ReactComponent as ArrowLeftSvg } from 'assets/svg/arrow-left.svg';
import { SubmenuItem } from 'interfaces/routes.interfaces';
import { MenuItem } from 'interfaces/sidebar.interfaces';
import { useAppSelector } from 'modules/store';
import { selectUserPermissions } from 'modules/user/selector';
import { cn } from 'utils/global';

interface Props extends MenuItem {
  dropdownIcon?: boolean;
  submenu?: SubmenuItem[];
  isSidebarVisible?: boolean;
  handleSubmenuItemClick: (e: React.MouseEvent<HTMLDivElement>, path: string) => void;
  dataCy: string | undefined;
}

const SidebarSubmenuItem: FC<Props> = ({
  icon,
  label,
  labelClassName,
  className,
  path,
  isSidebarVisible,
  active,
  handleSubmenuItemClick,
  submenu,
  dataCy,
}) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
  const submenuRef = useRef<HTMLDivElement>(null);

  const permissions = useAppSelector(selectUserPermissions);
  const navigate = useNavigate();

  const isSubmenuActive = useMemo(
    () => submenu?.some(({ path }) => pathname.startsWith(path || '')),
    [pathname, submenu]
  );

  const toggleSubmenu = () => {
    if (!submenu) {
      return;
    }

    setIsSubmenuOpen((prev) => !prev);
  };

  const isActive = active || isSubmenuActive;

  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (path) {
      navigate(path);
    }
  };

  const commonClasses = cn('text-neutral-600 dark:text-neutral-400 group-hover:text-neutral-900', {
    '!text-black': isActive,
  });

  useEffect(() => {
    if (isSubmenuActive) {
      setIsSubmenuOpen(true);
    }
  }, [isSubmenuActive]);

  return (
    <div>
      <div
        data-cy={dataCy}
        onClick={submenu ? toggleSubmenu : handleClick}
        className={cn(
          'sidebar-menu-item flex items-center justify-between py-2.5 bg-white-00 dark:bg-black-900 hover:bg-primary-100 dark:hover:bg-primary-100 pl-4 border-l-2 border-primary-300 hover:border-primary-600 h-9 group hover:rounded-[0.25rem]',
          { 'pl-9': !icon },
          {
            '!bg-primary-100 hover:bg-primary-100 border-primary-600 rounded-[0.25rem]': isActive,
          },
          className
        )}
      >
        <div className="flex items-center">
          {icon && (
            <FontAwesomeIcon
              icon={icon}
              className={cn(
                'text-neutral-400 group-hover:text-neutral-600 h-5',
                { '!text-primary-600': isSubmenuOpen && !isActive },
                {
                  '!text-primary-600': isActive,
                }
              )}
            />
          )}
          {isSidebarVisible && (
            <p
              className={cn(
                'text-sm ml-3 w-fit',
                commonClasses,
                labelClassName,
                { '!text-primary-600': isSubmenuOpen && !isActive },
                { '!text-primary-600': isActive }
              )}
            >
              {label}
            </p>
          )}
        </div>

        {submenu && isSidebarVisible && (
          <ArrowLeftSvg
            className={cn(
              'w-2 h-3 mr-3 fill-neutral-600 transition-transform',
              {
                'rotate-[-90deg] duration-200': isSubmenuOpen,
              },
              { '!fill-primary-600': isActive }
            )}
          />
        )}
      </div>

      <div
        className={cn('overflow-hidden transition-all duration-300', {
          'max-h-0': !isSubmenuOpen,
          'max-h-[1000px]': isSubmenuOpen,
        })}
      >
        <div ref={submenuRef}>
          {submenu
            ?.filter((item) => (item.permissionTag ? permissions[item.permissionTag] : true))
            .map((item) => (
              <SidebarSubmenuItem
                icon={item.icon}
                label={t(item.title)}
                path={item.path}
                key={item.title}
                submenu={item.submenu}
                active={pathname.startsWith(item.path || '')}
                handleSubmenuItemClick={(e) => handleSubmenuItemClick(e, item.path || '')}
                isSidebarVisible={isSidebarVisible}
                dataCy={item.dataCy}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default SidebarSubmenuItem;
