import { useCallback, useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import Webcam from 'react-webcam';

import { ReactComponent as RotateCameraSvg } from 'assets/svg/rotate-camera.svg';
import { ButtonVariants } from 'constants/common';
import { IMAGE_TYPES } from 'constants/global';
import { Button, Modal } from 'shared-components';
import FileUploaderButton from 'shared-components/FileUploaderButton';
import ModalControlButtons from 'shared-components/ModalControlButtons';
import { convertFileToBase64, extractMimeTypeAndDataFromBase64 } from 'utils/files';
import { cn } from 'utils/global';

import ProfileSettingsAvatarSection from './ProfileSettingsAvatarSection';

const IMAGE_CLASSNAME = 'max-w-full max-h-auto relative align-middle w-full h-auto md:h-56 object-cover';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (base64Data: string, mimeType: string) => void;
  handleNestedModalIsOpenedChange: (isNested: boolean) => void;
  isLoading: boolean;
}

const ProfileInformationAvatarModal = ({
  isOpen,
  onClose,
  onSubmit,
  isLoading,
  handleNestedModalIsOpenedChange,
}: Props) => {
  const [capturedAvatar, setCapturedAvatar] = useState<string | null>(null);
  const [uploadedImage, setUploadedImage] = useState<string | null>(null);
  const [facingMode, setFacingMode] = useState<MediaTrackConstraints['facingMode']>('user');
  const [isWebcamLoaded, setIsWebcamLoaded] = useState(false);
  const [isOpenAvatarSection, setIsOpenAvatarSection] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const { t } = useTranslation();
  const webcamRef = useRef<Webcam>(null);

  const captureAvatar = useCallback(() => {
    if (!webcamRef?.current) {
      return;
    }

    setIsUploading(false);
    setIsOpenAvatarSection(true);
    const avatarSrc = webcamRef.current?.getScreenshot();
    setUploadedImage(null);
    setCapturedAvatar(avatarSrc);
  }, [webcamRef]);

  const handleUploadAvatar = async (files: FileList) => {
    if (!files || files.length === 0) {
      return;
    }

    const base64File = await convertFileToBase64(files[0]);
    setCapturedAvatar(null);
    setUploadedImage(base64File);
    setIsUploading(true);
    setIsOpenAvatarSection(false);
  };

  const handleChangeFacingMode = () => {
    setFacingMode((currentMode) => (currentMode === 'user' ? 'environment' : 'user'));
  };

  const handleSubmit = () => {
    const imageData = uploadedImage || capturedAvatar;
    if (imageData) {
      const { base64Data, mimeType } = extractMimeTypeAndDataFromBase64(imageData);
      onSubmit(base64Data, mimeType);
    }
    onClose();
  };

  return (
    <Modal heading={t('ProfilePictureSettings')} isOpen={isOpen} onClose={onClose}>
      <div className="p-3.5">
        <div className="flex justify-between flex-col space-y-2 xs:flex-row xs:space-y-0">
          <FileUploaderButton buttonText={t('UploadPhoto')} handleUpload={handleUploadAvatar} fileType={IMAGE_TYPES} />
          {isOpenAvatarSection ? (
            <Button type="button" onClick={captureAvatar}>
              {t('CaptureImage')}
            </Button>
          ) : (
            <Button
              type="button"
              onClick={() => {
                setIsOpenAvatarSection(true);
                setIsUploading(false);
              }}
            >
              {t('TakeAPicture')}
            </Button>
          )}
        </div>
        {(isOpenAvatarSection || isUploading) && (
          <div className="flex justify-between flex-wrap flex-col lg:flex-row -mx-2 text-sm">
            {!isUploading && (
              <ProfileSettingsAvatarSection title={t('Live')}>
                {!isWebcamLoaded && <div className="skeleton-loader w-full h-56" />}
                <div className={cn('relative', { hidden: !isWebcamLoaded })}>
                  <Button
                    type="button"
                    className="absolute right-0 top-0 z-20"
                    variant={ButtonVariants.PLAIN}
                    onClick={handleChangeFacingMode}
                  >
                    <RotateCameraSvg />
                  </Button>
                  <Webcam
                    ref={webcamRef}
                    className={IMAGE_CLASSNAME}
                    audio={false}
                    screenshotFormat="image/jpeg"
                    videoConstraints={{ facingMode }}
                    onUserMedia={() => setIsWebcamLoaded(true)}
                  />
                </div>
              </ProfileSettingsAvatarSection>
            )}

            <ProfileSettingsAvatarSection title={t('Result')}>
              {(capturedAvatar || uploadedImage) && (
                <img
                  className={cn(IMAGE_CLASSNAME, { '!h-auto': isUploading })}
                  src={capturedAvatar || uploadedImage || ''}
                  alt="avatar-capture"
                />
              )}
            </ProfileSettingsAvatarSection>
          </div>
        )}
      </div>
      <ModalControlButtons isLoading={isLoading} onClose={onClose} onSubmit={handleSubmit} />
    </Modal>
  );
};

export default ProfileInformationAvatarModal;
